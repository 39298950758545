import { Growthchart } from '../../../../Data/ApexChart'
import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Chart from 'react-apexcharts';

const GrowthOverview = () => {
    return (
        <Card>

                <div title='Growth Overview' subtitle="80% Growth" />

            <CardBody className="p-0 growth-overview">
                <div id="chart-dashbord">
                    <Chart options={Growthchart.options} series={Growthchart.series} type="radialBar" height={380} />
                </div>
            </CardBody>
        </Card>
    );
};

export default GrowthOverview;