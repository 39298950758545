import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FileText,PlusSquare } from 'react-feather';
import { FormGroup, Row, Col, Card, CardBody } from 'reactstrap';
import { H2, H6, P, Breadcrumbs } from '../../../../AbstractElements';
import { db } from '../../../../Config/Config';
import {collection, getDocs, where, query, setDoc, doc} from "firebase/firestore";
import Usertable from "./Usertable";


const Userlist = (props) => {

  const [allUsers, setAllUsers] = useState([]);

  const fetchUsers= async () => {

      const data = []
      const querySnapshot = await getDocs(collection(db, "clients/maxpark-220731/users"));
      querySnapshot.forEach((doc) => {
      
        let objtUser = doc.get('userData');
        objtUser['uid']= doc.id;
      data.push(objtUser);
      });


      setAllUsers(data);
  }

  const[noUsers, setNoUsers] = useState('0');

  useEffect(() => {

    fetchUsers();
   

  }, []);



  return (
    <Fragment>
      <Row>
        <Col sm="12" md="12">
          <Card>
            <CardBody>
              <Row className="">
                <Col md="8">
                  <H2 attrH6={{ className: 'title' }}>Liste des utilisateurs</H2>
                  <P>Cette liste contient tous utilisateurs du système.</P>
                </Col>
                <Col md="4">
                  <Link to={`${process.env.PUBLIC_URL}/dashboard/users/add`}>
                    <button type='button' class='btn btn-primary btn-block btn-maxpark pull-right'><span class="icon-button-fix"><PlusSquare className="h-20"/></span>Créer un utilisateur</button>
                  </Link>
                </Col>
              </Row>

              <Row className="m-t-20">
                <Col md="12">
                  <Usertable userlist={allUsers}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment >

  )

}
export default Userlist;