import React, { Fragment, useState } from 'react';
import { Container, Col, Row, Card, CardBody, Form, CardHeader, FormGroup, Input, Collapse } from 'reactstrap';
import { Accordion } from 'react-bootstrap';
import { Btn,H2, H5, P, UL, LI } from '../../../AbstractElements';
import ContactForm from '../Form/contactForm';
import i18n from '../../../i18n';

const Contact = () => {

	const [isOpen, setIsOpen] = useState(1);
	const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));


	return (
		<Fragment>
			<div className="page-wrapper  bg-secondary h-300" id="pageWrapper">
				<Container>
					<Col md="6" className="offset-md-1 landing-slider-headline m-t-50 p-20">
						<h1 className="f-color-white">{i18n.t('ContactPage_Intro_Header')}<span className="f-color-primary f-w-700"> {i18n.t('ContactPage_Intro_HeaderAccent')}</span> {i18n.t('ContactPage_Intro_HeaderEnd')} </h1>

					</Col>
					<Col md="8">

					</Col>
				</Container>
			</div>
			<div class="f-bg-gray p-30">
				<Container className="">
					<h4 className="text-left p-20 m-b-50 f-w-400">{i18n.t('ContactPage_SubSection_Title')} <br />{i18n.t('ContactPage_SubSection_SubTitle')} </h4>

					<Row className="m-b-50">
						<Col lg="4" xl="4" md="4" className="">
							<div class="title p-l-40 p-r-40">
								<h2>{i18n.t('ContactPage_FAQ_Title')}</h2>
								<p>{i18n.t('ContactPage_FAQ_Txt')}</p>
							</div>
						</Col>
						<Col lg="8" md="8" xl="8" sm="12">

							<Accordion defaultActiveKey="0">

								<div className="default-according" id="accordion">
									<Card className='no-border'>
										<CardHeader>
											<H5 attrH5={{ className: 'mb-0' }} >
												<Btn attrBtn={{ as: Card.Header, className: 'btn btn-link btn-accordion', color: 'default', onClick: () => toggle(1) }} >
													{i18n.t('ContactPage_FAQ_1_Question')}<span className="digits"></span>
												</Btn>
											</H5>
										</CardHeader>
										<Collapse isOpen={isOpen === 1}>
											<CardBody>
												<P>{i18n.t('ContactPage_FAQ_1_1_Answer')}</P>
											</CardBody>
										</Collapse>
									</Card>
									<Card>
										<CardHeader>
											<H5 attrH5={{ className: 'mb-0' }} >
												<Btn attrBtn={{ as: Card.Header, className: 'btn btn-link btn-accordion', color: 'default', onClick: () => toggle(2) }} >
													{i18n.t('ContactPage_FAQ_2_Question')}<span className="digits"></span>
												</Btn>
											</H5>
										</CardHeader>
										<Collapse isOpen={isOpen === 2}>
											<CardBody>
												<UL>
													<LI>{i18n.t('ContactPage_FAQ_2_1_Answer')}</LI>
													<LI>{i18n.t('ContactPage_FAQ_2_3_Answer')}</LI>
													<LI>{i18n.t('ContactPage_FAQ_2_4_Answer')}</LI>
													<LI>{i18n.t('ContactPage_FAQ_2_5_Answer')}</LI>
												</UL>
											</CardBody>
										</Collapse>
									</Card>
									<Card>
										<CardHeader>
											<H5 attrH5={{ className: 'mb-0' }} >
												<Btn attrBtn={{ as: Card.Header, className: 'btn btn-link btn-accordion', color: 'default', onClick: () => toggle(3) }} >
													{i18n.t('ContactPage_FAQ_3_Question')}<span className="digits"></span>
												</Btn>
											</H5>
										</CardHeader>
										<Collapse isOpen={isOpen === 3}>
											<CardBody>
												<P>{i18n.t('ContactPage_FAQ_3_1_Answer')}</P>
											</CardBody>
										</Collapse>
									</Card>
									<Card>
										<CardHeader>
											<H5 attrH5={{ className: 'mb-0' }} >
												<Btn attrBtn={{ as: Card.Header, className: 'btn btn-link btn-accordion', color: 'default', onClick: () => toggle(4) }} >
													{i18n.t('ContactPage_FAQ_4_Question')}<span className="digits"></span>
												</Btn>
											</H5>
										</CardHeader>
										<Collapse isOpen={isOpen === 4}>
											<CardBody>
												<P>{i18n.t('ContactPage_FAQ_4_1_Answer')}</P>
												<P>{i18n.t('ContactPage_FAQ_4_2_Answer')}</P>
											</CardBody>
										</Collapse>
									</Card>
					
								</div>

							</Accordion>

						</Col>

					</Row>
				</Container>
			</div>
			<div class="f-bg-white">
				<Container>
					<Row className="m-b-50">
						<Col lg="4" xl="4" md="4" className="">
							<div class="title p-40">
								<H2>{i18n.t('ContactPage_FormSection_Title')}</H2>
								<P>{i18n.t('ContactPage_FormSection_Txt')}</P>
								<P>{i18n.t('ContactPage_FormSection_Txt2')}</P>
							</div>
						</Col>
						<Col lg="8" md="8" xl="8" sm="12">

							<ContactForm formType={'contact'} />
						</Col>
					</Row>
				</Container>
			</div>
		</Fragment >
	);
};

export default Contact;