import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

function index() {
  let [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");
    const session_id = searchParams.get("token");
{/*
    axios
    .post("https://api.maxparksolutions.com/payment-information-update", {
      id: id,
      session_id: session_id,
    })
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      if (error) {
        console.log(error);
      }
    });
  */}

  useEffect(() => {

   // updateData();
   
  }, []);

  return (
    <div className="page-wrapper" id="pageWrapper">
      <div className="">
        <Container>
          <div
            style={{
              height: "100vh",
            }}
          >
            <Col md="8" className="offset-md-2">
              <Card className="payment-form no-border no-background">
                <h2 class="p-l-20 p-r-20 m-t-40 text-white">Confirmation de paiement</h2>
                <CardBody className="p-50 m-b-40 p-b-40">
                  <Card className="">
                    <Col md="12">
                      <div
                        className={`confirmation-box loading-paymodal ${
                          true == true ? "" : "modalHide"
                        }`}
                      >
                        <div class="loader-container paymodal-loading-container  m-t-20">
                          <i className="fa fa-check-circle f-80 m-t-30 f-color-primary"></i>
                        </div>
                        <h2 class="loading-message f-20 p-20">
                          L'avis de {/*n°{id}*/}stationnement a été payé !
                        </h2>
                        <h3 class="loading-message f-color-primary f-16 p-l-40 p-b-20 p-r-40">
                          Merci d'avoir utilisé le système MaxPark Solutions{" "}
                          <br />
                          <span class="text-bold f-color-secondary">
                           
                          </span>
                        </h3>
                        <h3 class="loading-message f-color-primary f-16 p-l-40 p-b-20 p-r-40">
                          Une copie vous a été envoyé par courriel.
                        </h3>
                        <Link
                          to={`${process.env.PUBLIC_URL}/`}
                          className="btn btn-secondary btn-block p-t-15 p-b-15"
                        >
                          Retour à l'accueil
                        </Link>
                      </div>
                    </Col>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </div>
        </Container>
      </div>
    </div>
  );
}
export default index;
