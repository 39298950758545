import React, { useContext, useState, Fragment } from 'react';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../../AbstractElements';
import CheckContext from '../../../_helper/Customizer';

const Leftbar = (props) => {

    const { mixLayout, toggleSidebar } = useContext(CheckContext);
    const [toggle, setToggle] = useState(false);
    const [searchFocus, setSearchFocus] = useState(true);

    const openCloseSidebar = () => {
        setToggle(!toggle);
        toggleSidebar(toggle);
    };



    return (
        <Fragment>
            <div className={`main-header-left animate-width  ${props.searchistoggled ? "searchOpen" : ""}`}>
                {mixLayout ?
                    <div className={`logo-wrapper  ${props.searchistoggled ? "animated-hide" : ""}`}>
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/home`}>
                            <Image attrImage={{ className: 'img-fluid d-inline', src: `${require('../../../assets/images/logo/maxpark-logo-black@2x-50.png')}`, alt: '' }} />
                        </Link>
                    </div>
                    :
                    <div className={`dark-logo-wrapper ${props.searchistoggled ? "animated-hide" : ""}`}>
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/home`}>
                            <Image attrImage={{ className: 'img-fluid d-inline', src: `${require('../../../assets/images/logo/maxpark-logo-white@2x-50.png')}`, alt: '' }} />
                        </Link>
                    </div>
                }
                <div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
                    <AlignCenter className="status_toggle middle" id="sidebar-toggle" />
                </div>
            </div>
        </Fragment >
    );
};

export default Leftbar;