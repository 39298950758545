import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Target, Info, CheckCircle, PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { db } from '../../../../Config/Config';
import { collection, query, where, getDocs } from "firebase/firestore";
import { Breadcrumbs } from '../../../../AbstractElements';
import CusClass from '../Common/CusClass';

const ContratsList = () => {

    const [allData, setAllData] = useState([]);

    const fetchData = async () => {

        const data = []
        const querySnapshot = await getDocs(collection(db, "clients/maxpark-220731/contrats"));
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });


        setAllData(data);
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <Fragment>
            <Breadcrumbs parent="All Contracts" title="Contracts List" mainTitle="Contracts List" />
            <Container fluid={true}>
                <Row>
                    <Col md="12" className="project-list">
                        <Card>
                            <Row>
                                <Col>
                                    <div className="text-end">
                                        <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/dashboard/contracts/add`}> <PlusCircle />Nouveau Contrat</Link>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    {allData.map((contract) => {
                                        return (
                                            <CusClass item={contract} key={contract.id} />
                                        )
                                    })}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ContratsList;