import React, {Fragment, useEffect, useState} from 'react';
import { Breadcrumbs, Btn, H4 } from '../../../../AbstractElements';
import {  Address,  Cancel,  City,  Country,  Email,  PostalCode,  State,  CreateNewContract,  Save,  Telephone,
  TypeOfService,  DateOfSubmission,  DateOfContractTermination,  FieldCannotBeEmpty,  PleaseEnterValidPhoneNumber,  PleaseEnterValidEmail
} from '../../../../Constant';
import { useNavigate, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import {Row, Col, Card, CardBody,CardHeader, CardFooter, Form, FormGroup, Label, Input} from 'reactstrap';
import {collection, doc, getDocs, query, setDoc, where} from "firebase/firestore";
import {db} from "../../../../Config/Config";
import FsLightbox from 'fslightbox-react';
import userimg from '../../../../assets/images/user/user.png';
import i18n from '../../../../i18n';

const NewContract = () => {

  const [newContractId, setContractId] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [imgToggle, setImgToggle] = useState(false);

  const { register, handleSubmit, formState: {errors}, control} = useForm();

  const generateNewContractId = async () => {
    const data = []
    const dataID = []
    let latestID = 0;

    const querySnapshot = await getDocs(collection(db, "clients/maxpark-220731/contrats"));
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
      dataID.push(doc.data().id)
    });


    for (let i=0; i < dataID.length; i++) {
      if (dataID[i] > latestID) {
          latestID = dataID[i];
      }
    }

    console.log(latestID)
    setContractId(latestID + 1);
   
  }

  const formSubmit = async (data) => {
    
    setLoading(true);


    const contractData = {
      client: data.client,
      contact: data.contact,
      email: data.email,
      telephone: data.telephone,
      telExtension: data.telExtension,
      typeOfService: data.typeOfService,
      dateOfSubmission: data.dateOfSubmission,
      endOfContract: data.endOfContract,
      address: data.address,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      country: data.country,
      id: + newContractId
    }

    await setDoc(doc(db, "clients/maxpark-220731/contrats",  newContractId.toString()), contractData);
   
    setShouldRedirect(true);
  }

  const navigate = useNavigate();
  useEffect(() => {
    if(shouldRedirect) {
     // navigate('/dashboard/contracts');
     console.log("here");
     setLoadingCompleted(true);
    }
  }, [shouldRedirect])

  useEffect(() => {
    generateNewContractId();
  }, [])

  return (
    <Fragment>
      <Breadcrumbs
        parent="Dashboard"
        title={CreateNewContract}
        mainTitle={CreateNewContract}
      />
      {!loading ? (
        <Form className="card" onSubmit={handleSubmit(formSubmit)}>
          <CardHeader className="pb-0">
            <H4 attrH4={{ className: "card-title mb-0" }}>
              Client :{newContractId}
            </H4>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="4">
                    <div className="profile-title">
                      <H4 attrH4={{ className: "card-title m-t-20 f-16" }}>
                        Client : #{newContractId}
                      </H4>
                      <div className="media m-l-0">
                        <FsLightbox
                          openOnMount={false}
                          types={[...new Array(userimg)]}
                          toggler={imgToggle}
                          sources={[userimg]}
                        />
                        <img
                          src={userimg}
                          alt="Galerie"
                          class="media paymodal-img m-l-0 img-thumbnail media"
                          onClick={(event) => {
                            imgToggle
                              ? setImgToggle(false)
                              : setImgToggle(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-footer">
                      <Btn
                        attrBtn={{
                          className: "btn-block m-b-20 m-t-20",
                          color: "primary",
                        }}
                      >
                        Changer la photo
                      </Btn>
                    </div>
                  </Col>
                  <Col md="8">
                    <Row>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Client</Label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("client", { required: true })}
                          ></input>
                        
                          <span className="error-form">  {errors.client ? i18n.t("Global_Field_CannotBeEmpty") : ""}</span> 
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Contact</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="contact"
                            {...register("contact", { required: true })}
                          ></input>
                         
                          <span className="error-form">  {errors.contact ? i18n.t("Global_Field_CannotBeEmpty") : ""}</span> 
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">{Email}</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="contact"
                            {...register("email", {
                              required: true,
                              pattern:
                                /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                            })}
                          ></input>
                          
                          <span className="error-form">  {errors.email ? i18n.t("Global_Field_EnterValidEmail") : ""}</span> 
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">{i18n.t("Global_Field_Phone")}</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="telephone"
                            {...register("telephone", {
                              required: true,
                              pattern:
                                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                            })}
                          ></input>
                    
                          <span className="error-form">  {errors.telephone ? i18n.t("Global_Field_EnterValidPhoneNumber") : ""}</span> 
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Ext</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="telExtension"
                            {...register("telExtension")}
                          ></input>
                        </FormGroup>
                      </Col>

                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">{i18n.t("Admin_Contract_Form_Service_Type")}</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="typeOfService"
                            {...register("typeOfService", { required: true })}
                          ></input>
                        
                          <span className="error-form">  {errors.typeOfService ? i18n.t("Global_Field_CannotBeEmpty") : ""}</span> 
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                        <div className="daterange-card">
                            <div className="theme-form">
                              <FormGroup>
                                <Label className="form-label">
                                  {i18n.t("Admin_Contract_Form_Date_Created")}
                                </Label>
                                <Controller
                                  control={control}
                                  name="dateOfSubmission"
                                  render={({
                                    field: { onChange, onBlur, value },
                                  }) => (
                                    <DatePicker
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      selected={value}
                                      className="form-control"
                                    />
                                  )}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                          <div className="daterange-card">
                            <div className="theme-form">
                              <FormGroup>
                                <Label className="form-label">
                                {i18n.t("Admin_Contract_Form_Date_End")}
                                </Label>
                                <Controller
                                  control={control}
                                  name="endOfContract"
                                  render={({
                                    field: { onChange, onBlur, value },
                                  }) => (
                                    <DatePicker
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      selected={value}
                                      className="form-control"
                                    />
                                  )}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">{Address}</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            {...register("address", { required: true })}
                          ></input>
                        
                          <span className="error-form">  {errors.address ? i18n.t("Global_Field_CannotBeEmpty") : ""}</span> 
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">{City}</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="city"
                            {...register("city", { required: true })}
                          ></input>
                        
                          <span className="error-form">  {errors.city ? i18n.t("Global_Field_CannotBeEmpty") : ""}</span> 
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup>
                          <Label className="form-label">{State}</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="state"
                            {...register("state", { required: true })}
                          ></input>
                          
                          <span className="error-form">  {errors.state ? i18n.t("Global_Field_CannotBeEmpty") : ""}</span> 
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup>
                          <Label className="form-label">{PostalCode}</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="postalCode"
                            {...register("postalCode", { 
                                required: true,
                                pattern:/^[A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]$/i,
                            })}
                          ></input>
                          
                          <span className="error-form">  {errors.postalCode ? i18n.t("Global_Field_EnterValidPostalCode") : ""}</span> 
                        </FormGroup>
                      </Col>
                      <Col xs="6" sm="6" md="6">
                        <FormGroup>
                          <Label className="form-label">{"Pays"}</Label>
                          <input type="text" className="form-control btn-square" name="country" {...register("country", {required: true})}></input>                   
                          <span className="error-form">  {errors.country ? i18n.t("Global_Field_CannotBeEmpty") : ""}</span> 
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-end">
            <Btn attrBtn={{ color: "primary", type: "submit" }}>{Save}</Btn>
          </CardFooter>
        </Form>
      ) : (
        <Card>
          <CardBody>
            <Card className="no-border">
              <Row>
                <Col md="12">
                  <p class="p-t-30 text-center text-bold">
                    Veuillez ne pas quitter la page s.v.p. <br />
                  </p>
                  <p class="p-t-0 text-center">
                    Ajout du contrat : {newContractId}
                  </p>

                  <div className={`loader-box loading-paymodal`}>
                    {!loadingCompleted ? (
                      <div class="loader-container paymodal-loading-container m-t-40 m-b-40">
                        <div class="loader-box paymodal-loader-box">
                          <div class="paycard-loader"></div>
                        </div>
                      </div>
                    ) : (
                      <div class="loader-container paymodal-loading-container  m-b-40">
                        <i className="fa fa-check-circle f-80 m-t-30 f-color-primary"></i>
                      </div>
                    )}

                    {!loadingCompleted ? (
                      <h3 class="loading-message f-color-primary f-20 m-t-40">
                        Veuillez patienter ......
                      </h3>
                    ) : (
                      <h3 class="loading-message f-color-primary f-20 m-t-40">
                        Succès !
                      </h3>
                    )}

                    {!loadingCompleted ? (
                      <p class="p-t-0 text-center">
                        Vous serez rediriger vers la liste des contrats
                        automatiquement.
                      </p>
                    ) : (
                      <Link
                        to={`${process.env.PUBLIC_URL}/contracts`}
                        className="btn btn-secondary btn-block m-t-40 p-t-15 p-b-15"
                      >
                        Voir la liste des contrats
                      </Link>
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

export default NewContract;