import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng:'fr',
        // we init with resources
        resources: {
            fr: {
                translations: {

                    'MainPage_Intro_Header' : "Sécurisez votre",
                    'MainPage_Intro_HeaderAccent' : "stationnement",
                    'MainPage_Intro_HeaderEnd' : "avec Max Park Solutions",                   
                    
                    'MainPage_Intro_Txt' : "Gestion de stationnement privé clé en main.",
                    'MainPage_Intro_Btn': "Payer un avis",

                    'MainPage_Location_Title': "Nous déservons votre région",

                    'MainPage_Mission_Title': "Notre mission, vous garantir des aires de stationnement sécuritaire et accessible",
                    'MainPage_Mission_1_Title': "Conciliant",
                    'MainPage_Mission_1_Txt': "Une équipe respectueuse pour des aires de stationnement agréable et libre de conflits.",
                    
                    'MainPage_Mission_2_Title': "Sécuritaire",
                    'MainPage_Mission_2_Txt': "Offrez la paix d’esprit à vos clients grâce à nos patrouilles de surveillances nocturnes.",
                    
                    'MainPage_Mission_3_Title': "Accessible",
                    'MainPage_Mission_3_Txt': "Des places de stationnement facilement accessibles pour votre clientèle.",

                    'MainPage_Mission_4_Title': "Encadré",
                    'MainPage_Mission_4_Txt': "Un service clef en main de la gestion à l’entretien.",

                    'MainPage_Services_Title': "Nos services",
                    'MainPage_Services_Txt': "Notre équipe met en place un service complet et sécuritaire.",
                    
                    'MainPage_Services_1_Title': "Affichage & signalisation",
                    'MainPage_Services_1_List_1': "Création et installation d’affiches d’interdiction de stationnement.",
                    'MainPage_Services_1_List_2': "Mise en place d’espaces de stationnement réservés.",

                    'MainPage_Services_2_Title': "Gestion & Contrôle",
                    'MainPage_Services_2_List_1': "Gestion du trafic automobile",
                    'MainPage_Services_2_List_2': "Contrôle de l’accès à l’espace privé",
                    'MainPage_Services_2_List_3': "Émission de frais de stationnement",

                    'MainPage_Services_3_Title': "Remorquage & Assistance",
                    'MainPage_Services_3_List_1': "Remorquage disponible 24/7",
                    'MainPage_Services_3_List_2': "Localisation des véhicules remorqués",
                    'MainPage_Services_3_List_3': "Gestion des situations de crise",

                    'MainPage_Services_4_Title': "Sensibilisation & Prévention",
                    'MainPage_Services_4_List_1': "Sensibilisation auprès des contrevenants",
                    'MainPage_Services_4_List_2': "Présence aléatoire des agents sur les lieux",
                    'MainPage_Services_4_List_3': "Interventions pacifiques",

                    'MainPage_Soumission_Title': "Demande de soumission",
                    'MainPage_Soumission_Txt': "Vos espaces de stationnement seront exclusivement dédiés à vos clients. Notre équipe d’experts met en œuvre un service complet en appliquant des mesures préventives et dissuasives. Ensemble, nous veillons à la sécurité de votre aire de stationnement.",

                    'MainPage_Soumission_Form_Title': "Formulaire de",
                    'MainPage_Soumission_Form_Txt': "S’il vous plaît, soyez précis dans votre message.",

                    'MainPage_Form_Field_Name': "Nom",
                    'MainPage_Form_Field_Name_PlaceHolder': "Insérez votre nom et prénom",

                    'MainPage_Form_Field_Email': "Courriel",
                    'MainPage_Form_Field_Email_PlaceHolder': "Entrez votre courriel",

                    'MainPage_Form_Field_Enterprise': "Nom de l'entreprise",
                    'MainPage_Form_Field_Enterprise_PlaceHolder': "Entrez le nom de l'entreprise",

                    'MainPage_Form_Field_Phone': "Numéro de téléphone",
                    'MainPage_Form_Field_Phone_PlaceHolder': "Entrez un numéro de téléphone",

                    'MainPage_Form_Field_Message': "Message",
                    'MainPage_Form_Field_Message_PlaceHolder': "Écrivez votre message",

                    'MainPage_Form_Field_Submit': "Envoyer",

                    'MainPage_Form_ResponseTime': "Un représentant vous contactera dans un délais de 48h",

                    'SearchPage_Intro_Header' : "Payez un",
                    'SearchPage_Intro_HeaderAccent' : "avis",
                    'SearchPage_Intro_HeaderEnd' : "de stationnement",

                    'SearchPage_Intro_Txt' : "Vous avez en votre possession un avis de frais de stationnement ?",
                    'SearchPage_Intro_Txt2' : "Effectuez votre paiement dès maintenant.",
                    
                    'SearchPage_Field_Searching_PlaceHolder' : "(ex:1680812)",
                    'SearchPage_SearchBtn' : "Rechercher",
                    'SearchPage_SearchBtn_Searching' : "En cours...",

                    
                    'ServicePage_Intro_Header' : "Nos services sont",
                    'ServicePage_Intro_HeaderAccent' : "personnalisables",
                    'ServicePage_Intro_HeaderEnd' : "Tout pour répondre à vos besoins",  

                    'ServicePage_Service_Section_Title' : "Nos services", 
                    
                    'ServicePage_Service_1_Title': "Gestion du stationnement",
                    'ServicePage_Service_1_List_1': "Répondre aux appels de service",
                    'ServicePage_Service_1_List_2': "Effectuer des patrouilles",
                    'ServicePage_Service_1_List_3': "Fournir des vignettes aux détenteurs des véhicules autorisés",

                    'ServicePage_Service_2_Title': "Affichage et signalisation",
                    'ServicePage_Service_2_List_1': "Produire et installer les panneaux",
                    'ServicePage_Service_2_List_2': "Déterminer les limites des aires de stationnement",
                    'ServicePage_Service_2_List_3': "Rafraîchir la peinture des lignes de stationnement",

                    'ServicePage_Service_3_Title': "Patrouille",
                    'ServicePage_Service_3_List_1': "Présence d’un agent identifié sur place",
                    'ServicePage_Service_3_List_2': "Équipements technologiques modernes",
                    'ServicePage_Service_3_List_3': "Véhicules de patrouille disponibles 24/7",
                    'ServicePage_Service_3_List_4': "Émission et production de rapports détaillés",

                    'ServicePage_Service_4_Title': "Circulation",
                    'ServicePage_Service_4_List_1': "Fluidité de la circulation assurée par un agent en cas d’événements ou de travaux",
                    'ServicePage_Service_4_List_2': "Attribution d’espace de stationnement",
                    'ServicePage_Service_4_List_3': "Signalisation des espace restant",

                    'ServicePage_Service_5_Title': "Avis frais de stationnement",
                    'ServicePage_Service_5_List_1': "Avis émis aux usagers qui contreviennent à la réglementation",
                    'ServicePage_Service_5_List_2': "Sensibilisation aux contrevenants aperçus en direct",

                    'ServicePage_Service_5_Title': "Surveillance nocturne",
                    'ServicePage_Service_5_List_1': "Véhicule de service avec gyrophare",
                    'ServicePage_Service_5_List_2': "Surveillance par auto-patrouille la nuit",
                    'ServicePage_Service_5_List_3': "Historique des utilisateurs nocturnes pour les récidivistes",

                    'ServicePage_Service_6_Title': "Remorquage",
                    'ServicePage_Service_6_List_1': "Remorquage des véhicules qui enfreignent les règlements",
                    'ServicePage_Service_6_List_2': "Remorquage des véhicules bloquants des accès importants",
                    
                    'ServicePage_Soumission_Title': "Une aire de stationnement spécifique ? ",
                    'ServicePage_Soumission_SubTitle': "Faites-nous part de votre demande",
                    'ServicePage_Soumission_Txt':   "services sur-mesure selon vos besoins !",
                    'ServicePage_Soumission_Btn':   "Demande de soumission",

                    'ContactPage_Intro_Header' : "Transmettez votre",
                    'ContactPage_Intro_HeaderAccent' : "message",
                    'ContactPage_Intro_HeaderEnd' : "à l'équipe MaxPark Solutions", 
                    
                    'ContactPage_SubSection_Title': "Vous avez une question ?",
                    'ContactPage_SubSection_SubTitle': "La réponse se trouve sûrement dans notre FAQ",
                      
                    'ContactPage_FAQ_Title': "F.A.Q",
                    'ContactPage_FAQ_Txt': "Ici, nous avons rassemblé les réponses aux questions les plus fréquemment posées.",

                    'ContactPage_FAQ_1_Question': "Pourquoi est-ce que j’ai reçu un avis de frais de stationnement?",
                    'ContactPage_FAQ_1_1_Answer': "Parce que vous ne respectez pas la réglementation de la propriété privée où vous avez stationné votre véhicule. Référez-vous à votre avis pour voir le type de règlement qui n'a pas été respecté.",

                    'ContactPage_FAQ_2_Question': "Quelle est la liste des règlements ?",
                    'ContactPage_FAQ_2_1_Answer': "Stationnement de votre véhicule en dehors des heures d’ouverture du commerce.",
                    'ContactPage_FAQ_2_2_Answer': "Stationnement de votre véhicule sans autorisation ou sans vignette autorisée.",
                    'ContactPage_FAQ_2_3_Answer': "Stationnement de votre véhicule de manière à bloquer l’accès à un quai de livraison, un conteneur de déchets ou de recyclage, une porte d’entrée ou une entrée opérationnelle.",
                    'ContactPage_FAQ_2_4_Answer': "Stationnement de votre véhicule dans l’aire d’un commerce que vous n’avez pas fréquenté.",
                    'ContactPage_FAQ_2_5_Answer': "Pour plus de détails, n’hésitez pas à nous appeler sur les heures d’ouverture au 450-634-1808",
                    
                    'ContactPage_FAQ_3_Question': "Pourquoi est-ce que j’ai reçu un avis de frais de stationnement, même si j’ai fréquenté le commerce associé au stationnement?",
                    'ContactPage_FAQ_3_1_Answer': "Vous ne respecter pas la règlementation du stationnement si vous avez stationné votre véhicule pour ensuite vous rendre ailleurs qu’au commerce relié au stationnement.",

                    'ContactPage_FAQ_4_Question': "Comment faire pour contester un avis?",
                    'ContactPage_FAQ_4_1_Answer': `Vous pouvez contester votre avis en envoyant un courriel à contestation@maxparksolutions.com`,
                    'ContactPage_FAQ_4_2_Answer': "Assurez-vous d’indiquer le numéro de l’avis et de joindre au courriel les pièces justificatives. Les pièces justificatives doivent correspondre à la date et l’heure de l’émission de l’avis. Ensuite, dans un délai de moins de 48 h, vous recevrez une réponse de notre équipe.",

                    'ContactPage_FormSection_Title': "Formulaire de contact",
                    'ContactPage_FormSection_Txt': "Remplissez le formulaire de contact pour communiquer avec notre équipe.",
                    'ContactPage_FormSection_Txt2': "Il nous fera plaisir de discuter de votre projet et de vous soumettre notre meilleure offre de services.",


                    'Footer_Section_1_Title' : "Siège Social",
                    'Footer_Section_1_1_Txt' : "3310 100e Avenue",
                    'Footer_Section_1_2_Txt' : "Bureau 225",
                    'Footer_Section_1_3_Txt' : "Laval, Québec, H7T-0J7",
                    'Footer_Section_1_4_Txt' : "Tél : 450-634-1808",

                    'Footer_Section_2_Title' : "Informations",
                    'Footer_Section_2_1_Txt' : "Nos services",
                    'Footer_Section_2_2_Txt' : "Affichage et signalisation",
                    'Footer_Section_2_3_Txt' : "Patrouilles",
                    'Footer_Section_2_4_Txt' : "Avis de frais de stationnement",
                    'Footer_Section_2_5_Txt' : "Gestion de la circulation",

                    'Footer_Section_3_Title' : "Foire aux questions",
                    'Footer_Section_3_1_Txt' : "Mon véhicule a été remorqué",
                    'Footer_Section_3_2_Txt' : "Agent de circulation durant des travaux",
                    'Footer_Section_3_3_Txt' : "Règlements sur le stationnement privé",
                    'Footer_Section_3_4_Txt' : "Gestion de crise",

                    'Footer_Section_4_Title' : "Assitance d'urgence",
                    'Footer_Section_4_Title_Accent' : "24h/7",
                    'Footer_Section_4_1_Txt' : "Contactez l’assistance technique", 
                    'Footer_Section_4_2_Txt' : "Contactez le support", 

                    // ADMIN SEARCH PAGE 

                    'Admin_SearchPage_Search_Section_Title': "Filtre de résultats",
                    'Admin_SearchPage_Search_Section_Txt': "Veuillez cliquer la section pour avoir des proposition.",

                    'Admin_SearchPage_SearchResults_No_Search_Title_Txt': "Faites une recherche pour afficher un resultat.",
                    'Admin_SearchPage_SearchResults_No_Results_Title_Txt': "Aucun résultat trouvé avec vos paramètres.",
                    'Admin_SearchPage_SearchResults_Start_Search_Btn': "Débuter une recherche",

                    // ADMIN CONTRACTS PAGE

                    'Admin_Contract_ADD_Section_Title': "Ajout d'un contrat",
                    'Admin_Contract_Form_Date_Created' : "Date de création",
                    'Admin_Contract_Form_Date_End' : "Date de fin de contrat",
                    'Admin_Contract_Form_Service_Type' : "Type de service",

                    //FORMS FIELDS 
                    'Global_Field_Phone' : "Téléphone",

                    //FORMS ERROR MESSAGE
                    'Global_Field_CannotBeEmpty' : 'Ce champ ne peut être vide',
                    'Global_Field_EnterValidPhoneNumber' : 'Veuillez entrer un numéro de téléphone valide',
                    'Global_Field_EnterValidExtension' : 'Veuillez entrer une extension valide : #203',
                    'Global_Field_EnterValidEmail' : 'Veuillez entrer une addresse courriel valide par exemple : Jon.Sh@mail.com',
                    'Global_Field_EnterValidPostalCode' : 'Veuillez entrer le code postal ex :H1G 1A1',




                    
                                    
                }
            },
            en: {
                translations: {
                    'MainPage_Intro_Txt' : "Service complet de gestion de stationnement privé.",
                    'MainPage_Intro_Btn': 'Pay parking note',
                    'Dashboard': 'Dashboard',
                    'Default': 'Default',
                    'Ecommerce': 'Ecommerce',
                    'Widgets': 'Widgets',
                    'Chart': 'Chart',
                    'Applications': 'Applications',
                    'Ready to use Apps': 'Ready to use Apps'
                }
            }
        },
        fallbackLng: 'fr',
        debug: false,

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
