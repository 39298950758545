import React, { Fragment, useState, useEffect } from 'react';
import { Database, Grid, } from 'react-feather';
import { Card, CardBody, Col } from 'reactstrap';
import { H5, H6, LI, P, UL, Image } from '../../../AbstractElements';
import fold from '../../../assets/images/dashboard/folder.png';
import fold1 from '../../../assets/images/dashboard/folder1.png';

const FileSideBar = () => {
    const [IsOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(true);

    const handleResize = () => {
        if (window.innerWidth <= 1199) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    });

    const OnHandelClick = () => {
        setIsOpen(!IsOpen);
    };
    return (
        <Fragment>
            <Col xl="3" className="box-col-6 pr-0 file-spacing xl-30">
                <div className="md-sidebar job-sidebar">
                    {isMobile && <a className="btn btn-primary job-toggle" href="#javascript" onClick={OnHandelClick}>File filter</a>}
                    {/* <a className="btn btn-primary md-sidebar-toggle" href="#javascript" onClick={onClickHandle}></a> */}
                    <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${IsOpen ? 'open' : ''}`}>
                        <div className="file-sidebar">
                            <Card>
                                <CardBody>
                                    <UL attrUL={{ className: 'simple-list ' }}>
                                        <LI>
                                            <div className="btn btn-secondary"><Database />Avis</div>
                                        </LI>
                                        <LI>
                                            <div className="btn btn-secondary"><Database />Contrats</div>
                                        </LI>
                                        <LI>
                                            <div className="btn btn-secondary"><Database />Véhicules</div>
                                        </LI>
                                        <LI>
                                            <div className="btn btn-secondary"><Database />Storage</div>
                                        </LI>
                                    </UL>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </Col>
        </Fragment>
    );
};
export default FileSideBar;