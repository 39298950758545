import React, { Fragment } from 'react';
import SidebarMenuItems from './SidebarMenuItems';

const SidebarMenu = ({ setMainMenu, props, sidebartoogle, setNavActive }) => {

  return (
    <Fragment>

        <nav>
          <div className="main-navbar" >
              
            <div id="sidebar-menu">
              <SidebarMenuItems setMainMenu={setMainMenu} props={props} sidebartoogle={sidebartoogle} setNavActive={setNavActive} />
            </div>

          </div>
        </nav>
    </Fragment>

  );

};

export default SidebarMenu;