
import { AcivityChart } from '../../../Data/ApexChart';
import { H5, H6, Image, LI, P, UL } from '../../../AbstractElements';
import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Chart from 'react-apexcharts';

const TimelineCard = () => {
    return (
        <Card>
            <div class="card-header"><div class="header-top d-sm-flex align-items-center">
                <h5>Activité</h5><div class="center-content">
                    <p><i class="toprightarrow-primary fa fa-arrow-up me-2"></i>Semaine précédente 12.32%</p>
                </div></div>
            </div>
            <CardBody>
                <div className="chart-main activity-timeline update-line">
                    <div className="media">
                        <div className="activity-dot-primary"></div>
                        <div className="media-body d-block">
                        <h6 class="f-12"> <span className="font-primary">2023-01-18</span><span className="badge pill-badge-primary m-l-10">Payé</span> <span class="timer pull-right"><i className="fa fa-clock-o"></i> 11:24</span> </h6>
                            <h5 class="f-12">Avis payé <span className="font-primary">#31232</span> </h5>
                            <P>Client : Clinique Dentaire Rachel.</P>
                        </div>
                    </div>
                    <div className="media">
                        <div className="activity-dot-primary"></div>
                        <div className="media-body d-block">
                            <h6 class="f-12"> <span className="font-primary">2023-01-28</span> <span class="timer pull-right"><i className="fa fa-clock-o"></i> 15:41</span> </h6>
                            <h5 class="f-12">Nouveau avis <span className="font-primary">#53221</span> </h5>
                            <P>Quisque a consequat ante Sit amet magna at volutapt.</P>
                        </div>
                    </div>
                    <div className="media">
                        <div className="activity-dot-primary"></div>
                        <div className="media-body d-block">
                            <h6 class="f-12"> <span className="font-primary">2023-02-01</span> <span class="timer pull-right"><i className="fa fa-clock-o"></i> 21:36</span> </h6>
                            <h5 class="f-12">Modification avis <span className="font-primary">#534221</span> </h5>
                            <P>Quisque a consequat ante Sit amet magna at volutapt.</P>
                        </div>
                    </div>
                    <div className="media">
               
                        <div className="media-body d-block">

                            <div className="hospital-small-chart">

                                <div id="column-chart">
                                    <Chart options={AcivityChart.options} series={AcivityChart.series} type="bar" height={120} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default TimelineCard;