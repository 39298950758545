import React, { Fragment, useContext, useState, useEffect } from 'react';
import { H6, Image, P, UL, LI, Btn } from '../../../AbstractElements';
import { Card, Col, CardBody, Row, Container, Span } from 'reactstrap';
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import { fetchData } from './searchData';


const SearchResults = (props) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [resultsArray, setResultsArray] = useState([]);
    const [results, setResults] = useState(new Map());
    const [searchLoading, setSearchLoading] = useState(false);
    const [view, setView] = useState('nosearch');

    let isMounted = false;

    useEffect(() => {

        isMounted = true;
        if (isMounted) {
            if (props.searching && props.searching != '') {
                setSearchTerm(props.searching.toLowerCase().replaceAll(" ", "").replaceAll("/", "-"));

                setView('sending');

                const fetchedData = fetchData(props.searching).then((data) => {

                    if (data) {
                        setResults(data)
                        createSearchList(data)
                        setView('success');
                    }
                })

            } else {

                setView('nosearch');
            }

        }


    }, [props.searching]);

    const createSearchList = (data) => {
        let newList = [];

        if (data) {

            data.forEach((values, keys) => {
                newList.push(keys);
            })
            props.searchResult(newList)
            setResultsArray(newList);
        }

        return newList

    }



    const setSearchFocus = () => {
        console.log("setFocus");
    };

    const searchView = () => {
        switch (view) {
            // 'form': return formView;
            case 'nosearch': return noSearchView;
            case 'sending': return sendingView;
            case 'success': return successView;
            default: return noSearchView
        }
    }


    const noSearchView =
        <Card className="br-15">
            <CardBody className="text-center">
                <h5 class="text-center f-color-fadedgray"> {!results ? i18n.t('Admin_SearchPage_SearchResults_No_Search_Title_Txt') : i18n.t('Admin_SearchPage_SearchResults_No_Results_Title_Txt')} </h5>
                <button className='btn btn-fadedgray btn-lg m-t-20 p-t-15 p-b-15' onClick={setSearchFocus} >{i18n.t('Admin_SearchPage_SearchResults_Start_Search_Btn')}</button>
            </CardBody>
        </Card>


    const successView = createSearchList();


    const sendingView =
        <Card className="br-15">
            <p class='p-t-30 text-center text-bold'>Recherche en cours... ne pas quitter la page s.v.p. <br /></p>
            <p class='p-t-0 text-center'>Recherche : {props.searching}  </p>
            <div className={`loader-box loading-paymodal`}>

                <div class='loader-container paymodal-loading-container m-b-40'>
                    <div class='loader-box paymodal-loader-box'>
                        <div class='paycard-loader'></div>
                    </div>
                </div>
            </div>
        </Card>;

    //const successView = createSearchList();

    function setFoundLabel(element, matchword) {
        console.log(element + " :: " + matchword);
        if (element.includes(matchword)) {
            return (
                <span className="searchmark"> {element} </span>
            )
        } else {
            return (
                { element }
            )
        }


    }


    return (
        <Fragment>



            <Col md="12 m-b-40 search-page">

                <Row>
                    <p class="pb-4">{`Environs ${resultsArray.length} .... résultats trouvé(s)`}</p>
                    {resultsArray.map(id => (

                        <Col md="4" key={id} s="12">

                            <Card className={`br-15`}>

                                <div className="txt-search p-20">

                                    <div className="media">
                                        <span class="hideOverflow">
                                            <Image attrImage={{ className: 'img-search img-fluid m-r-20', src: `${results.get(id).img}`, alt: '' }} />
                                        </span>
                                        <div className="media-body">
                                            <H6 attrH6={{ className: 'f-w-600' }} >

                                                <Link to={`${process.env.PUBLIC_URL}/dashboard/avis/` + results.get(id).ticketid}>
                                                    {results.get(id).ticketid.includes(searchTerm) ?
                                                        <span className="searchmark">{results.get(id).ticketid}</span>
                                                        : results.get(id).ticketid
                                                    }

                                                </Link>
                                                {results.get(id).time.replaceAll("/", "-").includes(searchTerm) ?
                                                    <span className="pull-right f-12 searchmark">{results.get(id).time}</span>
                                                    : <span className="pull-right f-12">{results.get(id).time}</span>
                                                }

                                                {results.get(id).date.replaceAll("/", "-").includes(searchTerm) ?
                                                    <span className="pull-right f-12 searchmark">{results.get(id).date}</span>
                                                    : <span className="pull-right f-12">{results.get(id).date}</span>
                                                }
                                                <br />



                                                <span className="pull-right f-12 text-capitalized">{results.get(id).carplate.replaceAll(" ", "").replaceAll("-", "").toLowerCase().includes(searchTerm) ?

                                                    <span className="searchmark">{results.get(id).carplate.replaceAll(" ", "").replace("-", "")}</span>
                                                    : results.get(id).carplate.replaceAll(" ", "").replaceAll("-", "")
                                                }
                                                </span>


                                            </H6>
                                            <P>
                                                {results.get(id).carbrand.toLowerCase().replaceAll("-", "").includes(searchTerm) ?
                                                    <span className="searchmark">{results.get(id).carbrand}</span>
                                                    : results.get(id).carbrand
                                                }
                                                ,
                                                {results.get(id).carmodel.toLowerCase().replaceAll("-", "").includes(searchTerm) ?
                                                    <span className="searchmark">{results.get(id).carmodel}</span>
                                                    : results.get(id).carmodel
                                                }
                                            </P><br />

                                            <P>
                                                <span className="f-12 text-capitalized">{results.get(id).contract.replaceAll(" ", "").replaceAll("-", "").toLowerCase().includes(props.searching.toLowerCase().replaceAll(" ", "")) ?

                                                    <span className="searchmark">{results.get(id).contract}</span>
                                                    : results.get(id).contract

                                                } </span>
                                                <br />
                                                <span className="f-12 text-capitalized">{results.get(id).address.replaceAll(" ", "").replaceAll("-", "").toLowerCase().includes(props.searching.toLowerCase().replaceAll(" ", "")) ?

                                                    <span className="searchmark">{results.get(id).address}</span>
                                                    : results.get(id).address
                                                }
                                                </span>
                                            </P>



                                        </div>

                                    </div>


                                </div>

                            </Card>
                        </Col>

                    ))}

                </Row>
            </Col>


            <Col md="12 m-b-40">
                {searchView()}
            </Col>


        </Fragment >
    );
};

export default SearchResults;