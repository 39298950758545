import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import Export from "react-data-table-component"
import DataTable from 'react-data-table-component';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Row, Col, Card, CardBody, Input, InputGroup, InputGroupText } from 'reactstrap';
import { FileText } from 'react-feather';
import { Typeahead } from 'react-bootstrap-typeahead';
import { H2, H6, P, Breadcrumbs } from '../../../../AbstractElements';
import { firebase_rdb } from '../../../../Config/Config';
import DatePicker from 'react-datepicker';
import EditAvis from "../../../Dashboard/Avis/SingleAvis/EditAvis";
import { format } from 'date-fns'
import { Link } from 'react-router-dom';
import SweetAlert from 'sweetalert2';






const AvisList = (props) => {

  const [modal, setModal] = useState(false);
  const [modalID, setModalID] = useState(0);
  const toggle = () => setModal(!modal);

  const [headerData, setHeaderData] = useState('');
  const [headerCols, setHeaderCols] = useState('');

  const [ticketData, setTicketData] = useState('');

  const [data, setData] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [noTickets, setNoTickets] = useState(0);
  const [filters, setFilters] = useState([]);

  //Search Bar
  const todayDate = [format(new Date(), 'yyyy/MM/dd')];

 const paginationComponentOptions = {
  rowsPerPageText: 'Avis par page',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Tous',
};


  //Date Picker
  const [dateRange, setDateRange] = useState('');
  const [startDate, endDate] = dateRange;
  const [rangeDates, setRangeDates] = useState('');

  useEffect(() => {

    initTable();

  }, []);

  const handleDateChange = date => {

    setDateRange(date)
    //Wait for the Selection of the 2nd date

    if (date[1] != null) {

      let datePickerDates = {
        startDate: date[0],
        endDate: date[1]
      }
      setRangeDates(datePickerDates);
      initTable(datePickerDates);
    }
    return;
  }


  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success('Successfully Deleted !');
      }
    };

    return <button key="delete" className="btn btn-danger" onClick={handleDelete}>Delete</button>;
  }, [data, selectedRows, toggleCleared]);


  async function initTable(pDateOptions) {

    let dataTickets = [];
    let dataHeaders = [];
    let preFilterArray = [];
    let tempHeader = [];
    let pStartDate;
    let pEndDate;

    if (pDateOptions) {
      pStartDate = format(new Date(pDateOptions.startDate), 'yyyy/MM/dd');
      pEndDate = format(new Date(pDateOptions.endDate), 'yyyy/MM/dd');
    } else {
      //If dates are not set, set the date to today's date
      pStartDate = format(new Date(), 'yyyy/MM/dd');
      pEndDate = format(new Date(), 'yyyy/MM/dd');
    }

    let foundTicket = false;

    await firebase_rdb.once("value").then(function (snapshot) {

      let dataClients = snapshot.child("clients").child("maxpark-220731").child("agents").val();

      //Loop Through all the Clients
      for (var agents in dataClients) {

        let AgentTickets = dataClients[agents]

        //Loop Through all the Clients's tickets  
        Object.keys(AgentTickets.tickets).forEach(function (key, i) {

          let objTicket;

          dataHeaders = AgentTickets.tickets[key];

          if (i === 0) {
            tempHeader = dataHeaders
          }
            

          if (pStartDate <= dataHeaders.date && pEndDate >= dataHeaders.date) {
            let carPlate = dataHeaders.carplate.replace(' ', '').replace('-', '');
            foundTicket = true

            objTicket = {
              avis: dataHeaders.ticketid,
              client: dataHeaders.clientname,
              contractid: dataHeaders.contract,
              contractname: dataHeaders.contractname,
              marque: dataHeaders.carbrand,
              modele: dataHeaders.carmodel,
              plaque: carPlate,
              agent: dataHeaders.agent,
              date: dataHeaders.date,
              datetime: dataHeaders.datetime,
              time: dataHeaders.time,
              paiement: dataHeaders.payment ? dataHeaders.payment.paid === true ? true : false : false,
              paidprice: dataHeaders.payment ? dataHeaders.payment.paidprice : "",
              paiddate: dataHeaders.payment ? dataHeaders.payment.paymentdate : "",
              status: dataHeaders.status,
              prix: dataHeaders.price,
            }
       
            //Fill the filters array
            preFilterArray.push(AgentTickets.tickets[key].agent);
            preFilterArray.push(AgentTickets.tickets[key].contractname);
            //Fill the Tickets Array
            dataTickets.push(objTicket);
           
          }
        
        });
       
      }
      console.log(dataTickets)
    });

    if (!foundTicket && pStartDate && pStartDate) {
      toast.warning(`Aucun avis trouvé(s) ! Pour la période  début : ${pStartDate} fin: ${pEndDate}`);
      //SweetAlert.fire({ title: 'Aucun avis trouvé', text: `Pour la période  début : ${pStartDate} fin: ${pEndDate}`, icon: 'warning' }).then(function () { });
      return false
    }

    if (dataTickets) {
      setHeaderData(tempHeader);

      //Ajouter les filtres a la liste
      let filteredArray = [];
      preFilterArray = removeDuplicates(preFilterArray);

      for (var i = 0; i <= preFilterArray.length; i++) {
        if (preFilterArray[i]) {
          let objtAgent = {
            agent: preFilterArray[i]
          }
          filteredArray.push(objtAgent);
        }
      }

      setFilters(filteredArray);

      //Affichez le nombre de tickets dans la liste
      setNoTickets(dataTickets.length);
      setTicketData(dataTickets);

    }
    return;
  }


  function removeDuplicates(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }





  const handleFilterChange = (e => {

    if (e) {

      var searchData = ticketData.filter((item) => {

        if (item.agent.toString().toLowerCase().includes(e.agent.toLowerCase()) ||
          item.client.toString().toLowerCase().includes(e.agent.toLowerCase()) 

        ) {
          return item;
        }
      });

      setTicketData(searchData);

    }else{
      initTable(rangeDates);
    }

  })




  function showModal(avis_id) {
    setModalID(avis_id);
    setModal(!modal);

  }


   const tableColumns = [

    {
      
      name: 'Avis', selector: (row) => <><button  onClick={()=> showModal(row.avis)} className='bg-transparent border-0 cursor-pointer bg-green text-green-600 fill-green  stroke-green px-2 mx-2 '><i className="fa fa-plus hover:fa-green "></i></button>
      <Link to={`${process.env.PUBLIC_URL}/dashboard/avis/${row.avis}`}>{row.avis}</Link></>, accessor: "link", sortable: true, center: false,
      when: row => row.paiement === true, style: row => ({ borderLeft: row.paiement === true ? '1px solid red' : '' })
    },
    { name: 'Client', selector: (row) => <div >{row.client}</div>, sortable: true, center: false,  },
    { name: 'Contrat', selector: (row) => row.contractname, sortable: true, center: false, },
    { name: 'Contrat ID', selector: (row) => row.contractid, sortable: true, center: false, },
   // { name: 'Marque', selector: (row) => row.marque, sortable: true, center: false, },
   // { name: 'Modèle', selector: (row) => row.modele, sortable: true, center: false, },
    { name: 'Plaque', selector: (row) => row.plaque, sortable: true, center: false, },
    { name: 'Agent', selector: (row) => row.agent, sortable: true, center: false, },
    //{ name: 'Date/Heure', selector: (row) => row.date, sortable: true, center: false, },
    { name: 'Date', selector: (row) => row.date, sortable: true, center: false, },
    { name: 'Heure', selector: (row) => row.time, sortable: true, center: false, },
    { name: 'Status', selector: (row) => row.status, sortable: true, center: false, },
    { name: 'Paiement', selector: (row) => row.paiement ? row.paidprice : "-" , sortable: true, center: false, },
    { name: 'Date de paiement', selector: (row) => row.paiement ? row.paiddate : "-" , sortable: true, center: false, }
  
  ];

  return (
    <Fragment>
      <Row>
        <Col sm="12" md="12">
          <Breadcrumbs mainTitle='Admin' parent="Dashboard" title="Avis" />
          <Card>
            <CardBody>
              <Row className="">
                <Col md="8">
                  <H2 attrH6={{ className: 'title' }}>Liste des avis</H2>
                  <P>Cette liste contient tous les avis émis par les agents.</P>
                </Col>
                <Col md="4">
                  <button type='button' class='btn btn-secondary btn-block pull-right' onClick={() => downloadXLS()} ><FileText className="h-15" />Exporter {noTickets} avis</button>
                </Col>
              </Row>

              <Row className="m-t-50">
                <Col md="8">
                  <H6 attrH6={{ className: 'sub-title' }}>Filtres</H6>

                  <P>Contrat et/ou Agent</P>
                  <div id="prefetch">
                    <FormGroup className="mb-0">
                      <InputGroup className="pill-input-group">
                        <InputGroupText><i className="fa fa-search"></i></InputGroupText>
                        <Typeahead
                          id="multiple-typeahead"
                          clearButton
                          labelKey={'agent'}
                          multiple={false}
                          options={filters}
                          placeholder="Entrez un filtre"
                          onChange={e => handleFilterChange(e[0])}
                        />

                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
                <Col md="4">
                  <div className="daterange-card">
                    <H6 attrH6={{ className: 'sub-title' }}>Période</H6>
                    <P>Sélectionnez la période pour les avis.</P>
                    <div className="theme-form">
                      <FormGroup >
                        <InputGroup className="pill-input-group">
                          <InputGroupText><i className="fa fa-calendar"></i></InputGroupText>
                          <DatePicker
                            className="form-control digits"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => { handleDateChange(update) }}
                            isClearable={true}
                            dropdownMode="select"
                            dateFormat="yyyy/MM/dd/"
                            todayButton="Aujourd'hui"
                            calendarStartDay={1}
                            placeholderText={todayDate + ' - ' + todayDate}
                            showWeekNumbers
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <DataTable
                    className={"table-avis-list m-t-20"}
                    data={ticketData}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    noHeader
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[100, 200, 400, 500]}
                    paginationComponentOptions={paginationComponentOptions}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modal}  toggler={toggle} size="xl"  >
      <ModalHeader >
    <div className="flex justify-between"><span className='flex'> Avis:  {modalID}</span> <button  onClick={() => setModal(!modal)} className='flex float-right border-0'> x</button></div>
      </ModalHeader>
      <ModalBody className="bg-transparent w-[800px]">
      <EditAvis avis={modalID} />
      </ModalBody>
      <ModalFooter>
      <button  onClick={() => setModal(!modal)} className='flex float-right border-0 bg-secondary p-2 rounded text-white hover:text-primary text-semibold'> Fermer </button>
      </ModalFooter>
    </Modal>
    </Fragment >

  )

}
export default AvisList;