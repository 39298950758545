import React from 'react';
import { Suspense} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from '../CommonElements/Loader';
import { routes } from '../Route/Routes';
import LayoutRoutes from './LayoutRoutes';
import Login from '../Pages/Website/Login'

const Routers = () => {

        return (

                <BrowserRouter basename='/' >
                        <>
                                <Suspense fallback={<Loader />}>
                                        <Routes>
                                                <Route path='/' element={<LayoutRoutes />}>
                                                        {routes.map(({ path, Component, type }, i) => (
                                                                type === "client" ? <Route path={path} element={Component} key={i} /> : ''
                                                        ))};
                                                </Route>

                                                <Route exact path={`${process.env.PUBLIC_URL}/auth`} element={<Login />} />

                                                <Route path='/dashboard/*' element={<LayoutRoutes />}>
                                                        {routes.map(({ path, Component, type }, i) => (
                                                                type === "dashboard" ? <Route path={path} element={Component} key={i} /> : ''
                                                        ))};                                         
                                                </Route>                                   
                                        </Routes>
                                </Suspense>
                        </>
                </BrowserRouter>

        );
};

export default Routers;