import React from 'react';
import { Col, Row, Progress } from 'reactstrap';
import { H6, Image, LI, P, UL } from '../../../../AbstractElements';
import { NumberOfTickets, Telephone } from '../../../../Constant';
import {Link} from "react-router-dom";

const CusClass = ({ item }) => {
    return (
        <Col className="col-xxl-4" lg="6">
            <div className="project-box">
                <Link to={`${process.env.PUBLIC_URL}` + item.id}><h6>{item.client}</h6></Link>
                <div className="media">
                    {/*<Image attrImage={{ className: 'img-20 me-1 rounded-circle', src: `${require(`../../../../assets/images/${item.img}`)}`, alt: '' }} />*/}
                    <div className="media-body">
                        <P>{item.address}, {item.city}, {item.state}, {item.postalCode}</P>
                        <p>{Telephone}: {item.telephone}</p>
                    </div>
                </div>
            </div>
        </Col>

    );
};

export default CusClass;
