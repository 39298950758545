import { firebase_rdb } from '../../../Config/Config';

export const fetchData = async (pMatchWord) => {

  if (pMatchWord && pMatchWord != "") {
    try {

      let avisArray = [];
      let avisMap = new Map();

      const response = await firebase_rdb.once("value").then(function (snapshot) {

        const dataClients = snapshot.child("clients").child("maxpark-220731").child("agents").val();

        //Loop Through all the Clients
        for (var agents in dataClients) {

          //Loop Through all the Clients's tickets
          for (var avis in dataClients[agents].tickets) {

            let checkTicketID = dataClients[agents].tickets[avis].ticketid;

            console.log(checkTicketID);

            let checkCarPlate = dataClients[agents]?.tickets[avis].carplate.replaceAll(" ","").toLowerCase();
            let checkContract = dataClients[agents]?.tickets[avis].contract.replaceAll(" ","").toLowerCase();
            let checkAddress = dataClients[agents]?.tickets[avis].address.replaceAll(" ","").toLowerCase();
            let checkCarBrand = dataClients[agents]?.tickets[avis].carbrand.replaceAll("/","-").toLowerCase();
            let checkCarModel = dataClients[agents]?.tickets[avis].carmodel.replaceAll(" "," ").toLowerCase();
            let checkDate = dataClients[agents]?.tickets[avis].date.replaceAll("/","-").toLowerCase();
            let checkTime = dataClients[agents]?.tickets[avis].time.replaceAll(" "," ").toLowerCase();
           
           
            let sysID = checkTicketID + checkCarBrand + checkCarModel + checkCarPlate + checkContract + checkAddress +checkDate +checkTime;

            let avisID = avis.toLowerCase().toString();

            if(sysID.includes(pMatchWord.toString().toLowerCase().replace(" ", "").replace("/", "-"))){
              console.log(sysID);
              avisArray.push(sysID);
              avisMap.set(sysID, dataClients[agents].tickets[avis])
            }
            
          }

        }

       //console.log(avisMap);
      });

      const sendMap = await avisMap;
      return sendMap

    }
    catch (e) {
      console.log('An error occured while fetching the Avis list', e);
    }

  }


}