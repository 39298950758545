import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import UserList from './UserList';


const UserProfile = () => {

  

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Utilisateurs" parent="Users" title="User List" />
            <Container fluid={true}>
                <div className="edit-profile">
                    <Row>
                        <Col xl="12">
                            <UserList/>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default UserProfile;