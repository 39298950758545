import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Card, CardBody } from 'reactstrap';
import ContactForm from '../Form/contactForm';
import { Image, LI, UL, H2, P } from '../../../AbstractElements';

import background from '../../../assets/images/banner/maxpark-bg.jpg';
import i18n from '../../../i18n';

const MainPage = () => {


	return (
		<Fragment>
			<div className="page-wrapper landing-slider modern-sidebar" id="pageWrapper" style={{ backgroundImage: `url(${background})` }}>
				<Container className="landing-home-contain">
					<Col md="6" className="offset-md-1 landing-slider-headline">
						<h1 className="f-color-white">{i18n.t('MainPage_Intro_Header')}<span className="f-color-primary f-w-700"> {i18n.t('MainPage_Intro_HeaderAccent')}</span> {i18n.t('MainPage_Intro_HeaderEnd')}</h1>
						<p attrPara={{ className: 'sub-content ' }} className="f-color-white f-20 m-t-30">{i18n.t('MainPage_Intro_Txt')}</p>
						<Link to={`${process.env.PUBLIC_URL}/recherche`} className="btn btn-primary btn-maxpark btn-lg m-t-30 min-w-200 p-t-15 p-b-15">{i18n.t('MainPage_Intro_Btn')}</Link>
					</Col>
					<Col md="8">

					</Col>
				</Container>
			</div>
			<div class="f-bg-white">
				<Row>
					<Col md="12">
						<Row className="m-t-0">
							<Col md="12">
								<Card className="landing-location p-20">
									<h2 class="text-center m-b-0 m-t-10">{i18n.t('MainPage_Location_Title')}</h2>
									<Row>
										<Col md="3" xs="6">
											<CardBody>

												<ul class="list-carbonblack">
													<li><i className="fa fa-check-square" /><span class="list-item">Bas-Saint-Laurent</span></li>
													<li><i className="fa fa-check-square" /><span class="list-item">Beauce</span></li>
													<li><i className="fa fa-check-square" /><span class="list-item">Centre-du-Québec</span></li>
												</ul>
											</CardBody>
										</Col>
										<Col md="3" xs="6">
											<CardBody>
												<ul class="list-carbonblack">
													<li><i className="fa fa-check-square" /><span class="list-item">Côte-Nord</span></li>
													<li><i className="fa fa-check-square" /><span class="list-item">Estrie</span></li>
													<li><i className="fa fa-check-square" /><span class="list-item">Grand Montréal</span></li>
												</ul>
											</CardBody>
										</Col>
										<Col md="3" xs="6">
											<CardBody>

												<ul class="list-carbonblack">
													<li><i className="fa fa-check-square" /><span class="list-item">Lanaudière</span></li>
													<li><i className="fa fa-check-square" /><span class="list-item">Laurentides</span></li>
													<li><i className="fa fa-check-square" /><span class="list-item">Mauricie</span></li>
												</ul>
											</CardBody>
										</Col>
										<Col md="3" xs="6">
											<CardBody>

												<ul class="list-carbonblack">
													<li><i className="fa fa-check-square" /><span class="list-item">Nord-du-Québec</span></li>
													<li><i className="fa fa-check-square" /><span class="list-item">Outaouais</span></li>
													<li><i className="fa fa-check-square" /><span class="list-item">Saguenay Lac St-Jean</span></li>
												</ul>
											</CardBody>
										</Col>
									</Row>

								</Card>

							</Col>

						</Row>
					</Col>
				</Row>
			</div>
			<div class="f-bg-white">
				<Container className="landing-home-contain">
					<h2 className="text-center p-50 f-w-400">{i18n.t('MainPage_Mission_Title')}</h2>
					<Row className="p-t-0 m-t-40">
						<Col xl="3" lg="3" md="6" sm="12">
							<Card className="no-border">
								<CardBody className="text-center p-0 m-t-10">
									<i className="icofont icofont-hand icon-main-page"></i>
									<h2 className="m-t-10 f-w-500 l-s-0">{i18n.t('MainPage_Mission_1_Title')}</h2>
									<div class="title title-section m-0 p-20 p-t-0 f-w-400">
										<h2 class="f-w-400">{i18n.t('MainPage_Mission_1_Txt')}</h2>
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="3" lg="3" md="6" sm="12">
							<Card className="no-border">
								<CardBody className="text-center p-0 m-t-10">
									<i className="icofont icofont-police-cap icon-main-page"></i>
									<h2 className="m-t-10 f-w-500 l-s-0">{i18n.t('MainPage_Mission_2_Title')}</h2>
									<div class="title title-section m-0 p-20 p-t-0 f-w-400">
										<h2 class="f-w-400">{i18n.t('MainPage_Mission_2_Txt')}</h2>
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="3" lg="3" md="6" sm="12">
							<Card className="no-border">
								<CardBody className="text-center p-0 m-t-10">
									<i className="icofont icofont-safety icon-main-page"></i>
									<h2 className="m-t-10 f-w-500 l-s-0">{i18n.t('MainPage_Mission_3_Title')}</h2>
									<div class="title title-section m-0 p-20 p-t-0 f-w-400">
										<h2 class="f-w-400">{i18n.t('MainPage_Mission_3_Txt')}</h2>
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="3" lg="3" md="6" sm="12">
							<Card className="no-border">
								<CardBody className="text-center p-0 m-t-10">
									<i className="icofont icofont-order icon-main-page"></i>
									<h2 className="m-t-10 f-w-500 l-s-0">{i18n.t('MainPage_Mission_4_Title')}</h2>
									<div class="title title-section m-0 p-20 p-t-0 f-w-400">
										<h2 class="f-w-400">{i18n.t('MainPage_Mission_4_Txt')}</h2>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

				</Container>
			</div>
			<div class="f-bg-gray">
				<Container className="p-40 section-nos-services">
					<Row className="m-t50 m-b-50">
						<Col lg="3" xl="3" md="3" className="">
							<div class="title p-t-20 p-b-20">
								<h2>{i18n.t('MainPage_Services_Title')}</h2>
							</div>
						</Col>
						<Col lg="8" xl="8" md="8">
							<div class="services-text m-t-20">
								<p>{i18n.t('MainPage_Services_Txt')}</p>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg="6" xl="3" md="6">
							<Card className="no-border bg-carbonblack card">
								<CardBody className="">
									<h5>{i18n.t('MainPage_Services_1_Title')}</h5>
									<ul class="list-carbonblack">
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_1_List_1')}</li>
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_1_List_2')}</li>
									</ul>
								</CardBody>
							</Card>

						</Col>
						<Col lg="6" xl="3" md="6">
							<Card className="no-border bg-carbonblack card">
								<CardBody className="">
									<h5>{i18n.t('MainPage_Services_2_Title')}</h5>
									<ul class="list-carbonblack">
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_2_List_1')}</li>
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_2_List_2')}</li>
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_2_List_3')}</li>
									</ul>
								</CardBody>
							</Card>

						</Col>
						<Col lg="6" xl="3" md="6">
							<Card className="no-border bg-carbonblack card">
								<CardBody className="">
									<h5>{i18n.t('MainPage_Services_3_Title')}</h5>
									<ul class="list-carbonblack">
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_3_List_1')}</li>
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_3_List_2')}</li>
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_3_List_3')}</li>
									</ul>
								</CardBody>
							</Card>

						</Col>
						<Col lg="6" xl="3" md="6">
							<Card className="no-border bg-carbonblack card">
								<CardBody className="">
									<h5>{i18n.t('MainPage_Services_4_Title')}</h5>
									<ul class="list-carbonblack">
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_4_List_1')}</li>
										<li><i className="fa fa-check-square" />{i18n.t('MainPage_Services_4_List_2')}</li>
									</ul>
								</CardBody>
							</Card>

						</Col>
					</Row>
				</Container >
			</div>

			<div class="f-bg-white">
				<Container className="container-fluid">
					<Row>
						<Col lg="4" md="4" xl="4" sm="12">
							<Card className="no-border p-t-20">
								<CardBody className="">
									<div class="title">
										<h2>{i18n.t('MainPage_Soumission_Title')}</h2>
									</div>
									<div class="soumission-text p-t-20">
										<p>{i18n.t('MainPage_Soumission_Txt')}</p>
									</div>
								</CardBody>
							</Card>
						</Col>

						<Col lg="8" md="8" xl="8" sm="12">
							<ContactForm formType={'soumission'} />
						</Col>

					</Row>
				</Container>

			</div>

		</Fragment >
	);
};

export default MainPage;