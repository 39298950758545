import React, { useContext, useState, Fragment } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { H1, H3, P } from '../../../AbstractElements';
import PayCard from '../Payer/paycard';
import i18n from '../../../i18n';

const SearchTicket = (props) => {

    const [messageID, setMessageID] = useState('');
    const [sendTicketID, setSendTicketID] = useState('');
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [sending, setSending]= useState(false);

    const sendToModal = (event) => {

        if (messageID == '') {
            setError(true);
            setErrorMsg("Veuillez entrer un numéro d\'avis");
           
            return
        }

        if (!/^[0-9]*$/i.test(messageID)) {
            setError(true)
            setErrorMsg('Numéro d\'avis invalide');
            
            return
        }

        setShowModal(true);
        setSendTicketID(messageID);
        
        return messageID;
    }

    const handleChange = event => {
        setMessageID(event.target.value)
        if (error) {
            setError(false);
        }
    };

    const sendingStatus = (e) => {
        setSending(e);
    }

    return (
        <Fragment>
            <Container className="searchbox-container">
                <Row className='search-banner'>
                    <Col md="12" lg="4" className="fit-content searchbox-bg" >
                        <div class="searchTicket-search">
                            <div>
                                <H1 className="text-light">{i18n.t('SearchPage_Intro_Header')}<span className="f-color-primary f-w-700"> {i18n.t('SearchPage_Intro_HeaderAccent')} </span> <br />{i18n.t('SearchPage_Intro_HeaderEnd')}</H1>
                                <P className="text-light f-18" >{i18n.t('SearchPage_Intro_Txt')} <br />{i18n.t('SearchPage_Intro_Txt2')}</P>
                            </div>
                        </div>

                        <div class="pb-0 mb-12 col-12 p-r-0 mb-12">
                            <div class="row">
                                <div class="xl-50 mb-6 col-5 p-r-0 mb-3">
                                    <input placeholder={i18n.t('SearchPage_Field_Searching_PlaceHolder')} value={messageID} onChange={handleChange} name="ID" type="text" className={`form-control search-bar  ${!error ? '' : 'search-banner-error shake animated'}`}               
                                        onKeyPress={(event) => {
                                            if (error) {
                                                setError(false);
                                            }

                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                setErrorMsg('Veuillez entrer des numéros uniquement')
                                                setError(true);
                                            }

                                            if (event.target.value.length > 9 ) {
                                                event.target.value = event.target.value.substr(0,9);
                                            }

                                        }}

                                    />
                                </div>                           
                                <div class="xl-50 mb-6 col-5 p-r-0 mb-3">
                                    <button class="btn btn-primary btn-maxpark btn-lg  min-w-200 p-t-20 p-b-20" onClick={() => sendToModal(messageID)}>{!sending ? i18n.t('SearchPage_SearchBtn') : i18n.t('SearchPage_SearchBtn_Searching')}</button>
                                </div>
                            </div>
                        </div>
                        <span className={`error-form  ${!error ? 'hidden' : 'fadeIn'}`}>{errorMsg}</span>
                    </Col>
                    <Col md="12" lg="5">
                        <PayCard showModalCard={showModal} searchTicketID={sendTicketID} paypage={false} searchpage={true}  searching={sendingStatus}/>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default SearchTicket;