import { Breadcrumbs, H5 } from '../../../../AbstractElements';
import TicketList from './TicketList';
import TicketTable from './TicketTable';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { Fragment, useState, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";

const SupportTickitContain = () => {

    const mapStyles = {
        width: "100%",
        height: "400px"
      };
      const layer = L.tileLayer(`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`, {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      });
    
    
      const mapParams = {
        center: [45.508888, -73.561668],
        zoom: 10,
        layers: [layer]
      };


    useEffect(() => {

        var container = L.DomUtil.get('map');
    
        if (container != null) {
          container._leaflet_id = null;
        }
    
    
        L.map("map", mapParams);
      }, []);

      

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Secteurs' parent="Données" title="Carte des secteurs" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Row className="m-b-20">
                            <div>
                                <div id="map" style={mapStyles} />
                            </div>
                        </Row>

                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{'Clients'}</H5>
                                <span>{'List of tickets'}</span>
                            </CardHeader>
                            <CardBody>
                                <TicketList />
                                <TicketTable />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default SupportTickitContain;