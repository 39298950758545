import React, { Fragment } from 'react';
import { Container, Col, Row, Card, CardBody, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { H2, H5, P, Btn } from '../../../AbstractElements';
import imgLogo from '../../../assets/images/logo/maxpark-logo-white@2x-50.png';
import i18n from '../../../i18n';

const Services = () => {
    return (
        <Fragment>
            <div className="page-wrapper  bg-secondary h-300" id="pageWrapper">
                <Container>
                    <Col md="6" className="landing-slider-headline m-t-50 p-20">
                        <h1 className="f-color-white">{i18n.t('ServicePage_Intro_Header')}<span className="f-color-primary f-w-700"> {i18n.t('ServicePage_Intro_HeaderAccent')} </span>{i18n.t('ServicePage_Intro_HeaderEnd')}</h1>
                    </Col>
                    <Col md="8">

                    </Col>
                </Container>
            </div>
            <div class="f-bg-white">

                <Container className="">
                    <Row>
                        <Col md="12">
                            <Row className="m-0 p-0">
                                <div className="service-card-banner bg-service-card m-t-40 p-0 br-tl-15">
                                    <CardBody className="p-0">
                                        <Row className="p-0 gutter-x-0">
                                            <Col md="6" className="services-bg-img text-center">
                                                <Card className="bg-transparent no-border">
                                                    <CardBody>
                                                        <img src={imgLogo} class="m-t-50 p-50" alt="logo-maxpark"></img>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="6" className="services-card-txt  p-40">
                                                <H2>{i18n.t('ServicePage_Service_Section_Title')}</H2>
                                                <P>Pour que vos aires de stationnement soient respéctées et exclusivement dédiées à vos clients, notre équipe d'expert
                                                    met en œuvre un service complet en appliquant des mesures : préventives et dissuasives, en contrôlant vos espaces privés,
                                                    et en utilisant les mesures nécessaires face aux conflits et
                                                    abus de stationnement. </P>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </div>
                            </Row>
                            <Row className="m-t-30">
                                <Col md="4" className="service-card-same-height">
                                    <Card className="bg-service-card service-card-same-height">
                                        <CardBody className="">
                                            <h5>{i18n.t('ServicePage_Service_1_Title')}</h5>
                                            <ul class="list-carbonblack">
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_1_List_1')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_1_List_2')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_1_List_3')}</span></li>
                                            </ul>
                                        </CardBody>
                                    </Card>

                                </Col>
                                <Col md="4">
                                    <Card className="bg-service-card service-card-same-height">
                                        <CardBody className="">
                                            <h5>{i18n.t('ServicePage_Service_2_Title')}</h5>
                                            <ul class="list-carbonblack">
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_2_List_1')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_2_List_2')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_2_List_3')}</span></li>
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="bg-service-card service-card-same-height ">
                                        <CardBody className="">
                                            <h5>{i18n.t('ServicePage_Service_3_Title')}</h5>
                                            <ul class="list-carbonblack">
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_3_List_1')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_3_List_2')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_3_List_3')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_3_List_4')}</span></li>
                                            </ul>
                                        </CardBody>
                                    </Card>

                                </Col>
                            </Row>

                            <Row className="">
                                <Col md="4" className="service-card-same-height">
                                    <Card className="bg-service-card service-card-same-height  br-bl-15">
                                        <CardBody className="">
                                            <h5>{i18n.t('ServicePage_Service_4_Title')}</h5>
                                            <ul class="list-carbonblack">
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_4_List_1')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_4_List_2')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_4_List_3')}</span></li>
                                            </ul>
                                        </CardBody>
                                    </Card>

                                </Col>
                                <Col md="4">
                                    <Card className="bg-service-card service-card-same-height">
                                        <CardBody className="">
                                            <h5>{i18n.t('ServicePage_Service_5_Title')}</h5>
                                            <ul class="list-carbonblack">
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_5_List_1')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_5_List_2')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_5_List_3')}</span></li>
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="bg-service-card service-card-same-height  br-br-15">
                                        <CardBody className="">
                                            <h5>{i18n.t('ServicePage_Service_6_Title')}</h5>
                                            <ul class="list-carbonblack">
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_6_List_1')}</span></li>
                                                <li><i className="fa fa-check-square" /><span class="list-item">{i18n.t('ServicePage_Service_6_List_2')}</span></li>
                                            </ul>
                                        </CardBody>
                                    </Card>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>


                <Row>
                    <Col md="12 m-b-40">
                        <Container className="m-t-40">
                            <Card className="br-15 f-bg-gray">
                                <CardBody className="text-center">
                                    <h5 class="text-center"> {i18n.t('ServicePage_Soumission_Title')} </h5>
                                    <h6 class="text-center f-400"> {i18n.t('ServicePage_Soumission_SubTitle')}</h6>
                                    <Link to={`${process.env.PUBLIC_URL}/contact`} className="btn btn-primary btn-maxpark btn-lg m-t-20 p-t-15 p-b-15">{i18n.t('ServicePage_Soumission_Btn')}</Link>
                                    <p class="text-center m-t-30"> {i18n.t('ServicePage_Soumission_Txt')}</p>
                                </CardBody>
                            </Card>
                        </Container>
                    </Col>

                </Row>

            </div>
        </Fragment >
    );
};

export default Services;