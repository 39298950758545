import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col } from 'reactstrap';
import { H3, P } from '../../../AbstractElements';
import { Car } from '../../../Data/SvgIcons';

const page404 = () => {
    return (
        <Fragment>
            <div className="page-wrapper" id="pageWrapper">
                <div className="error-wrapper">
                    <Container>
                        <div className="error-page1">
                            <div className="svg-wrraper mb-0">
                                <Car />
                            </div>
                            <Col md="8" className="offset-md-2">
                                <h3 class="" style={{color: '#dcd2bc'}}>Oops! Page non disponible. </h3>
                                <p class="sub-content" style={{color: '#6f869f'}}> La page que vous avez tenté de visiter n'existe pas ou a été déplacée.</p>                              
                    
                                <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-primary btn-lg"> Accueil</Link>
                            </Col>
                        </div>
                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default page404;