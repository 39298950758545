import React, { Fragment, useEffect, useState } from 'react';
import { Settings } from 'react-feather';
import { Link } from 'react-router-dom';
import { H6, Image, LI, UL, P } from '../../../AbstractElements';
import man from '../../../assets/images/dashboard/1.png';

const Profile = (props) => {
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));
    const [profile, setProfile] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);
        setName(localStorage.getItem('Name'));


    }, [setProfile, setName]);

    return (
        <Fragment>


            <div className={`p-10 m-t-10 company-settings-btn ${props.hidden ? 'hideThis' : ''}`}>

                <div className="media">
                    <Image attrImage={{ className: 'img-fluid rounded-circle me-2 img-50 m-t-10', src: profile, alt: 'Entreprise-Logo' }} />
                    <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}>
                        <div className="media-body">
                            <P attrPara={{ className: 'mb-0 font-roboto f-w-600 f-14' }}>MaxPark</P>
                            <span class='mb-0 font-roboto f-12 f-color-light-text'>1555 Bd de l'Avenir Bureau #306<br />Laval, QC H7S-2N5</span>
                        </div>
                    </Link>

                </div>
            </div>

        </Fragment >
    );
};

export default Profile;