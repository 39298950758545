import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import i18n from '../../../i18n';

const ContactForm = (props) => {

    const formType  = props.formType;

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [errorForm, setErrorForm] = useState(false);
    const [errorFormMsg, setErrorFormMsg] = useState('');
    const [successForm, setSucessForm] = useState(false);
    const [formBlock, setFormBlock] = useState(false);


    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [entreprise, setEntreprise] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorEntreprise, setErrorEntreprise] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);


    const [errorEmailMsg, setErrorEmailMsg] = useState('');
    const [errorNameMsg, setErrorNameMsg] = useState('');
    const [errorEntrepriseMsg, setErrorEntrepriseMsg] = useState('');
    const [errorPhoneMsg, setErrorPhoneMsg] = useState('');
    const [errorMessageMsg, setErrorMessageMsg] = useState('');

    axios.defaults.withCredentials = true;

    useEffect(() => {


    });



    //Clear the Form after sending
    const clearForm = event => {
        setEmail('');
        setName('');
        setEntreprise('');
        setPhone('');
        setMessage('');
    }

    const clearErrors = event => {
        setErrorEmailMsg(false);
        setErrorNameMsg(false);
        setErrorPhone(false);
        setErrorMessage(false);     

        setErrorEmailMsg('');
        setErrorNameMsg('');
        setErrorPhoneMsg('');
        setErrorMessageMsg('');   
        
    }

    const validateForm = (e) => {

        setErrorFormMsg('');//Reset the Global Error Message
        setErrorForm(false);

        let isValid = true // By Default the form is set to be valid.

        if (!email) {
            setErrorEmail(true);
            setErrorEmailMsg('Veuillez entrer une addresse courriel');
            isValid = false;

        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {

            setErrorEmail(true);
            setErrorEmailMsg('Veuillez entrer une addresse courriel valide par exemple : John.Smith@email.com');
            isValid = false;

        } else {
            setErrorEmail(false);

        }

        if (!name) {
            setErrorName(true);
            setErrorNameMsg('Veuillez entrer le nom complet');
            isValid = false;

        } else {
            setErrorName(false);
        }

        if (!phone) {
            setErrorPhone(true);
            setErrorPhoneMsg('Veuillez entrer un numéro de téléphone');
            isValid = false;
        } else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(phone)) {

            setErrorPhone(true);
            setErrorPhoneMsg('Veuillez entrer un numéro de téléphone valide ex: 514-555-1234');
            isValid = false;

        } else {
            setErrorPhone(false);
        }

        if (!message) {
            setErrorMessage(true);
            setErrorMessageMsg('Veuillez écrire un message');
            isValid = false;

        } else {
            setErrorMessage(false);
        }

        return isValid;
    }


    const validateByTarget = (e) => {

        switch (e.name) {

            case 'email':
                if (!email) {
                    setErrorEmail(true);
                    setErrorEmailMsg('Veuillez entrer une addresse courriel');
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {

                    setErrorEmail(true);
                    setErrorEmailMsg('Veuillez entrer une addresse courriel valide par exemple : John.Smith@email.com');

                } else {
                    setErrorEmail(false);
                }
                break;


            case 'name':
                if (!name) {
                    setErrorName(true);
                    setErrorNameMsg('Veuillez entrer le nom complet');


                } else {
                    setErrorName(false);
                }
            break;
            
            case 'phone':
                if (!phone) {
                    setErrorPhone(true);
                    setErrorPhoneMsg('Veuillez entrer un numéro de téléphone');
        
                } else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(phone)) {

                    setErrorPhone(true);
                    setErrorPhoneMsg('Veuillez entrer un numéro de téléphone valide ex: 514-555-1234');

                } else {
                    setErrorPhone(false);
                }
                break;
            
            case 'message':
                if (!message) {
                    setErrorMessage(true);
                    setErrorMessageMsg('Veuillez écrire un message');
                } else {
                    setErrorMessage(false);
                }
            break;

        }

    }

    const onSubmit = (e) => {

        e.preventDefault();

        const formIsValid = validateForm();

        if (formIsValid) {
            clearErrors(); // Clear all previous errors if everything is valid.
            setFormBlock(true); // Block The form to prevent button spamming
            
            var contactRequest = {          
                name : name,
                email: email,
                entreprise: entreprise,
                phone: phone,
                message:message,
                formType: formType
            }      
           
            axios.post('https://api.maxparksolutions.com/sendmessage', contactRequest,{
                withCredentials: true
         
            }).then((response) => {

                    if(response.status >= 200 && response.status <= 226  ){
                        setSucessForm(true)
                        setErrorForm(true);
                        setErrorFormMsg("Votre message à bien été reçu. Merci !");
                        setFormBlock(false);
                        clearForm();
                    }
        
                }).catch(function (error) {

                    setSucessForm(false)
                    setErrorForm(true)
                    setFormBlock(false);
                    setErrorFormMsg("Une erreur est survenu lors de l'envoi du message. Veuillez recommencer.");

                });

        } else {

            return false;
        }

    };


    const handleChange = event => {

        const name = event.target.name;

        switch (name) {
            case 'email': setEmail(event.target.value); break;
            case 'name': setName(event.target.value); break;
            case 'phone': setPhone(event.target.value); break;
            case 'entreprise': setEntreprise(event.target.value); break;
            case 'message': setMessage(event.target.value); break;
        }

    };


    return (
        <Fragment>

            <Form className='theme-form row x p-20' onSubmit={handleSubmit(onSubmit)}>

                <div class='p-10 m-t-20'>
                    <h5>{i18n.t('MainPage_Soumission_Form_Title')} {formType}</h5>
                    <span>{i18n.t('MainPage_Soumission_Form_Txt')}</span>
                </div>

                <FormGroup className='mb-3 col-6 p-r-0 '>
                    <Label className='f-w-900'>{i18n.t('MainPage_Form_Field_Name')} </Label>
                    <Input
                        className='form-control'
                        type='text'
                        name='name'
                        placeholder={i18n.t('MainPage_Form_Field_Name_PlaceHolder')}
                        onChange={handleChange}
                        //onPaste={(event) => { { event.preventDefault(); } }}
                        onBlur={(event) => { { validateByTarget(event.target) } }}
                        onKeyPress={(event) => {
                            if (event.target.value.length <= 30) {
                                setErrorName(false);
                            }

                            if (!/^([ \u00c0-\u01ffa-zA-Z'\-])+$/.test(event.key)) {
                                event.preventDefault();
                                setErrorNameMsg('Veuillez entrer du texte seulement')
                                setErrorName(true);
                            }

                            if (event.target.value.length >= 40) {
                                event.preventDefault();
                                setErrorNameMsg('Ce champ est limité à 40 caractères');
                                setErrorName(true);
                            }

                        }}

                    />
                    <span className={`error-form  ${!errorName ? 'hidden' : ''}`}>{errorNameMsg}</span>
                </FormGroup>

                <FormGroup className='mb-3 col-6 p-r-0 '>
                    <Label className='f-w-900'>{i18n.t('MainPage_Form_Field_Email')}</Label>
                    <Input
                        className='form-control'
                        type='text'
                        name='email'
                        placeholder={i18n.t('MainPage_Form_Field_Email_PlaceHolder')}
                        onChange={handleChange}
                        //onPaste={(event) => { { event.preventDefault(); } }}
                        onBlur={(event) => { { validateByTarget(event.target) } }}
                    />
                    <span className={`error-form  ${!errorEmail ? 'hidden' : ''}`}>{errorEmailMsg}</span>
                </FormGroup>

                <FormGroup className='mb-3 col-6 p-r-0 '>
                    <Label className='f-w-900'>{i18n.t('MainPage_Form_Field_Enterprise')}</Label>
                    <Input
                        className='form-control'
                        type='text'
                        name='entreprise'
                        placeholder={i18n.t('MainPage_Form_Field_Enterprise_PlaceHolder')}
                        onChange={handleChange}
                        //onPaste={(event) => { { event.preventDefault(); } }}
                        onBlur={(event) => { { validateByTarget(event.target) } }}

                    />
                    <span className={`error-form  ${!errorEntreprise ? 'hidden' : ''}`}>{errorEntrepriseMsg}</span>
                </FormGroup>
                <FormGroup className='mb-3 col-6 p-r-0'>
                    <Label className='f-w-900'>{i18n.t('MainPage_Form_Field_Phone')}</Label>
                    <Input
                        className='form-control'
                        type='text'
                        name='phone'
                        placeholder={i18n.t('MainPage_Form_Field_Phone_PlaceHolder')}
                        onChange={handleChange}
                        //onPaste={(event) => { { event.preventDefault(); } }}
                        onBlur={(event) => { { validateByTarget(event.target) } }}
                        onKeyPress={(event) => {
                            if (/[aA-zZ]/.test(event.key)) {
                                event.preventDefault();
                                setErrorPhoneMsg('Veuillez entrer des numéros seulement')
                                setErrorPhone(true);
                            }

                            if (event.target.value.length == 3) {
                                event.target.value = event.target.value + '-';
                            }

                            if (event.target.value.length == 7) {
                                event.target.value = event.target.value + '-';
                            }

                            if (event.target.value.length > 11) {
                                event.preventDefault();
                                setErrorPhoneMsg('Ce champ est limité à 10 caractères')
                                setErrorPhone(true);
                               
                            }


                        }}

                    />
                    <span className={`error-form  ${!errorPhone ? 'hidden' : ''}`}>{errorPhoneMsg}</span>
                </FormGroup>
                <FormGroup className="mb-3 col-12 mb-3">
                    <Label className='f-w-900'>{i18n.t('MainPage_Form_Field_Message')}</Label>
                    <Input
                        className='form-control'
                        type='textarea'
                        name='message'
                        rows="4"
                        placeholder={i18n.t('MainPage_Form_Field_Message_PlaceHolder')}
                        onChange={handleChange}
                        //onPaste={(event) => { { event.preventDefault(); } }}
                        onBlur={(event) => { { validateByTarget(event.target) } }}

                    />
                    <span className={`error-form  ${!errorMessage ? 'hidden' : ''}`}>{errorMessageMsg}</span>

                </FormGroup>
                <h6 class="f-color-light-text f-s-italic f-w-300 p-10">{i18n.t('MainPage_Form_ResponseTime')}</h6>
                <span className={`error-form ${successForm ? ' error-form-success' : ''}  ${!errorForm ? 'hidden' : ''} ` }>{errorFormMsg}</span>

                <div className='col-12 p-r-10'>
                    <button type='submit' disabled={formBlock} class='btn btn-primary btn-maxpark btn-block  p-10 m-w-100 pull-right' onClick={onSubmit}>{i18n.t('MainPage_Form_Field_Submit')}</button>
                </div>

            </Form>
		</Fragment >
	);
};

export default ContactForm;