import React, { useContext, useState, Fragment } from 'react';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image, UL} from '../../../AbstractElements';
import CheckContext from '../../../_helper/Customizer';
import CustomizerContext from '../../../_helper/Customizer';
import DollarSign from '../../../assets/images/ui/dollar.png';


const MainMenu = () => {
    const { sidebarResponsive } = useContext(CustomizerContext);
    const { toggleSidebar } = useContext(CheckContext);
    const [toggle, setToggle] = useState(true);

    const openCloseSidebar = () => {
        setToggle(true);
        toggleSidebar(toggle);
       
    };

    return (
        <Fragment>
            <div className="main-menu">
       
                <div className="logo-wrapper">
                    <Link to={`${process.env.PUBLIC_URL}/`}>
                        <Image attrImage={{ className: 'img-fluid d-inline', src: `${require('../../../assets/images/logo/maxpark-logo-white@2x-50.png')}`, alt: '' }} />
                    </Link>
                </div>

                <div className="nav-right col pull-right right-menu p-0">
                    <UL attrUL={{ className: `simple-list d-flex flex-row nav-menus ${sidebarResponsive ? 'open' : ''}` }} >
                    <nav class="navbar navbar-light p-0" id="navbar-example2"><a class="navbar-brand" href="javascript:void(0)"> <img class="img-fluid" src="../assets/images/logo/logo.png" alt=""/></a>
                    <ul class="landing-menu nav nav-pills client-menu">
                        <li class="nav-item"><Link to={`${process.env.PUBLIC_URL}`} className="nav-link">Accueil</Link></li>
                        <li class="nav-item"><img src={DollarSign} className="p-absolute top-15" alt="dollars"/><Link to={`${process.env.PUBLIC_URL}/recherche`} className="nav-link  m-l-10 right-85p">Payer un avis</Link></li>
                        <li class="nav-item"><Link to={`${process.env.PUBLIC_URL}/services`} className="nav-link">Services</Link></li>
                        <li class="nav-item"><Link to={`${process.env.PUBLIC_URL}/contact`} className="nav-link">Contact</Link></li>
                    </ul>
                    </nav>
                    </UL>
                </div>
     
                <div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
                    <AlignCenter className="status_toggle middle" id="sidebar-toggle" />
                </div>
            </div>
        </Fragment >
    );
};

export default MainMenu;