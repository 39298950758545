import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, InputGroupText  } from 'reactstrap';
import { Search } from 'react-feather';
import { Typeahead } from 'react-bootstrap-typeahead';
import {  useNavigate  } from 'react-router-dom';
import { H2, H3, P, Btn } from '../../../AbstractElements';
import { useParams } from 'react-router-dom';
import SearchResults from './SearchResults';
import i18n from '../../../i18n';


const SearchPage = (props) => {
    const navigate = useNavigate();
    const { terms } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [Data, setData] = useState([]);
   
   
    const handleChange = event => {

        console.log(event.target.value);
        setSearchTerm(event.target.value)
        //return navigate(`/dashboard/search/${event}`);
    };

    const handleSubmit = event => {

        return navigate(`/dashboard/dashboard/search/${event.target.value}`);
    };



    useEffect(() => {
        
    },[])

    return (
        <Fragment>
            <Container fluid={true}>
                <Row className="m-0 p-0">
                    <Card className="m-t-40 p-0 br-15">
                        <CardBody className="p-0">
                            <Row className="p-0">
                                <Col md="8" className="services-card-txt  p-40">
                                    <H2>{i18n.t('Admin_SearchPage_Search_Section_Title')}</H2>
                                    <P>{i18n.t('Admin_SearchPage_Search_Section_Txt')}</P>
                           
                                </Col>

                                <Col md="4" className="search-bg-img text-center br-tr-15 br-br-15">
                                    <Card className="bg-transparent no-border ">
                                        <CardBody>

                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <Row>
                    <Col md="12">
                        <SearchResults searching={searchTerm? searchTerm : terms} searchResult={setSearchResults}/>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default SearchPage;