import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Label,
} from "reactstrap";
import { H1, H2, Image, LI, UL, Btn } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import mastercard from "../../../assets/images/ecommerce/mastercard.png";
import visa from "../../../assets/images/ecommerce/visa.png";
import axios from "axios";
import PayCard from "./paycard.jsx";
import { useGoogleReCaptcha, GoogleReCaptcha } from "react-google-recaptcha-v3";

const Checkout = () => {

  const [payCardData, setPayCardData] = useState("");
  const [sending, setSending] = useState(false);

  const {
    register,
     handleSubmit,
    formState: { errors },
  } = useForm();

  const { id, success } = useParams();
  const [view, setView] = useState("form");
  const [formBlock, setFormBlock] = useState(false);
  const [userIp, setUserIp] = useState("000.000");

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [card, setCard] = useState("");
  const [cvc, setCvc] = useState("");
  const [date, setDate] = useState("");
  

  const [numRef, setNumRef] = useState("");

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorPostalCode, setErrorPostalCode] = useState(false);
  const [errorCard, setErrorCard] = useState(false);
  const [errorCvc, setErrorCvc] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorPayment, setErrorPayment] = useState(false);

  const [errorEmailMsg, setErrorEmailMsg] = useState("");
  const [errorNameMsg, setErrorNameMsg] = useState("");
  const [errorPostalCodeMsg, setErrorPostalCodeMsg] = useState("");
  const [errorCardMsg, setErrorCardMsg] = useState("");
  const [errorCvcMsg, setErrorCvcMsg] = useState("");
  const [errorDateMsg, setErrorDateMsg] = useState("");
  const [errorPaymentMsg, setErrorPaymentMsg] = useState("");

  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  // useEffect(() => {
  //   //Get the user's Ip Address
  //   // getUserIP();

  //   //Clear the Form after sending
  //   const clearErrors = (event) => {
  //     //setEmail('');
  //     setName("");
  //     setPostalCode("");
  //     setCard("");
  //     setCvc("");
  //     setDate("");
  //     setNumRef("");
  //   };

  //   const clearFields = (event) => {
  //     setErrorEmailMsg("");
  //     setErrorNameMsg("");
  //     setErrorPostalCodeMsg("");
  //     setErrorCardMsg("");
  //     setErrorCvcMsg("");
  //     setErrorDateMsg("");
  //     setErrorPaymentMsg("");
  //   };
  // }, [userIp, date]);

  // const onVerify = useCallback((token) => {
  //   //console.log(token);
  //   // setToken(token);
  // });

  const getUserIP = async () => {
    await axios({
      method: "get",
      url: "https://geolocation-db.com/json/",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://maxparksolutions.com",
      },
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setUserIp(data.IPv4);
        return data.IPv4;
      })

      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };

  const sendingStatus = (e) => {
    setSending(e);
  };

  const getCardData = (e) => {
    setPayCardData(e);    
  };

  // const { executeRecaptcha } = useGoogleReCaptcha();

  // const verifyCaptcha = async (data) => {
  //   try {
  //     const newToken = executeRecaptcha("payer");
  //     console.log({ data, newToken });
  //   } catch (err) {
  //     throw new Error("Token error");
  //   }
  // };

  // const onSubmit = (e) => {
  //   e.preventDefault();

  //   verifyCaptcha();

  //   const formIsValid = validateForm();

  //   //Exit if form is not valid
  //   if (!formIsValid) {
  //     return false;
  //   }

  //   clearErrors(); // Clear all previous errors
  //   setView("sending");

  //   setFormBlock(true);

  //   let payRequest = {
  //     id: id,
  //     email: email,
  //     postalcode: postalCode.replace("-", "").toLowerCase(),
  //     name: name,
  //     card: card,
  //     expdate: date.replace("/", ""),
  //     cvc: cvc,
  //     userip: userIp,
  //     ticketData: payCardData,
  //   };

  //   axios
  //     .post("http://localhost:5000/payer", payRequest)
  //     //axios.post('https://api.maxparksolutions.com/payer', payRequest)
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .then((data) => {
  //       let paymentStatus = data.status;
  //       let errorCode = data.errcode;
  //       let msg = data.msg;
  //       let refNum = data.ref;

  //       if (paymentStatus == "failed") {
  //         setErrorPayment(true);
  //         setErrorPaymentMsg(msg);
  //         setView("form");
  //         setFormBlock(false);
  //       } else {
  //         //If status is not "failed" then set the confirmation number and show success
  //         setNumRef(refNum);
  //         //Show Payment Complete View
  //         setView("success");
  //         setFormBlock(false);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error) {
  //         console.log(error);
  //       }

  //       // Something happened in setting up the request that triggered an Error
  //       setCard("");
  //       setErrorPayment(true);
  //       setErrorPaymentMsg(
  //         "Une erreur est survenu avec le paiement. Veuillez recommencer."
  //       );
  //       setView("form");
  //       setFormBlock(false);
  //     });
  // };

  // const handleChange = (event) => {
  //   const name = event.target.name;

  //   switch (name) {
  //     case "email":
  //       setEmail(event.target.value);
  //       break;
  //     case "name":
  //       setName(event.target.value);
  //       break;
  //     case "postalcode":
  //       setPostalCode(event.target.value);
  //       break;
  //     case "card":
  //       setCard(event.target.value);
  //       break;
  //     case "cvc":
  //       setCvc(event.target.value);
  //       break;
  //     case "date":
  //       setDate(event.target.value);
  //       break;
  //   }
  // };

  // const handleKeyPressed = (event) => {
  //   dateFormat(event);
  // };

  // const dateFormat = (event) => {
  //   let key = event.key || event.charCode;
  //   let textVal = event.target.value;

  //   if (event.key != "Backspace") {
  //     if (/^[0-9]{2}$/i.test(textVal)) {
  //       event.target.value = event.target.value + "/";
  //     }
  //   } else {
  //     event.target.value = event.target.value.substr(0, 6);
  //   }
  // };

  // const clearErrors = (event) => {
  //   setErrorEmail(false);
  //   setErrorName(false);
  //   setErrorPostalCode(false);
  //   setErrorCard(false);
  //   setErrorCvc(false);
  //   setErrorDate(false);
  //   setErrorPayment(false);
  // };

  useEffect(() => {
    if ({success}) {
      alert("payment successfull")
    }
  }, [])

  let formView = (
    <Card className="payment-form m-t-50 no-border no-background">
      <H1 className="p20 m-t-30">Payer un avis</H1>
      <span
        className={`error-form f-20 p-l-20 p-r-20  ${
          !errorPaymentMsg ? "hidden" : "fadeIn"
        }`}
      >
        {errorPaymentMsg}
      </span>
      <CardBody className="form-container">
        <Row>
          <Col md="12">
            <div class="p-0 m-b-25">
              <h5>Facturation</h5>
              <p>
                Une note de confirmation de paiement sera envoyé à l'adresse
                courriel ci-dessous.
              </p>
            </div>
            <button
              type="submit"
              class="btn btn-primary btn-maxpark btn-block p-absolute r-0 m-t-20 p-t-15 p-b-15 "
              onClick={handleSubmit}
            >
              Confirmer et payer
            </button>
            <GoogleReCaptcha onVerify={(t) => console.log({ t })} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  let successView = (
    <Card className="payment-form no-border no-background">
      <h2 class="p-l-20 p-r-20 m-t-40">Confirmation de paiement</h2>
      <CardBody className="p-50 m-b-40 p-b-40">
        <Card className="">
          <Row>
            <Col md="12">
              <div
                className={`confirmation-box loading-paymodal ${
                  true == true ? "" : "modalHide"
                }`}
              >
                <div class="loader-container paymodal-loading-container  m-t-20">
                  <i className="fa fa-check-circle f-80 m-t-30 f-color-primary"></i>
                </div>
                <h2 class="loading-message f-20 p-20">
                  L'avis n°{id} a été payé !
                </h2>
                <h3 class="loading-message f-color-primary f-16 p-l-40 p-b-20 p-r-40">
                  Le numéro de référence est le : <br />
                  <span class="text-bold f-color-secondary">{numRef}</span>
                </h3>
                <h3 class="loading-message f-color-primary f-16 p-l-40 p-b-20 p-r-40">
                  Une copie a été envoyé par courriel
                </h3>
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="btn btn-secondary btn-block p-t-15 p-b-15"
                >
                  Retour à l'accueil
                </Link>
              </div>
            </Col>
          </Row>
        </Card>
      </CardBody>
    </Card>
  );

  const pageView = () => {
    switch (view) {
      // 'form': return formView;
      case "form":
        return formView;
      case "sending":
        return sendingView;
      case "success":
        return successView;
      default:
        return formView;
    }
  };

  return (
    <Fragment>
      <div class="f-bg-white">
        <Container className="max-w-1800">
          <Col md="12">
            <Row>
              <Col md="6" className="">
                {pageView()}
              </Col>
              <Col md="4">
                {
                  <PayCard
                    showModalCard={true}
                    searchTicketID={id}
                    payPage={true}
                    sendCardData={getCardData}
                    searching={sendingStatus}
                  />
                }
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </Fragment>
  );
};

export default Checkout;
