import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Media } from 'reactstrap';
import { Image } from '../../../../AbstractElements';
import userimg from '../../../../assets/images/user/user.png';

const Userstable = (props) => {

    const allusers = props.userlist;

    const tableColumns = [

        {name: "Employé", selector: (row) => <Link to={`${process.env.PUBLIC_URL}/dashboard/users/${row.employeeid}`}>
            <Media><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${row.photo && row.photo !=""  ? row.photo :userimg }`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center"><div>{row.employeeid}</div></Media></Media></Link>, accessor: "link", sortable: true, center: false,
            when: row => row.paiement === 'oui', style: row => ({ borderLeft: row.paiement === 'oui' ? '' : '' })},
        { name: "Prénom", selector: (row) => row.firstname, sortable: true, center: false, },
        { name: "Nom", selector: (row) => row.lastname, sortable: true, center: false, },
        { name: "Date début", selector: (row) => row.hiredate, sortable: true, center: false, },
        { name: "Bonus", selector: (row) => row.bonus, sortable: true, center: false, },
        { name: "Terminé", selector: (row) => row.terminated ? row.terminationdate : 'non', sortable: true, center: false, },
        { name: "Type", selector: (row) => row.usertype, sortable: true, center: false, }
    ];

    
  const paginationComponentOptions = {
    rowsPerPageText: 'Utilisateurs par page',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Tous',
  };
  



    return (
        <Fragment>
            <div className="table-responsive support-table">
                <DataTable
                    columns={tableColumns}
                    data={allusers}
                    striped={true}
                    center={true}
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[100, 200, 400, 500]}
                    paginationComponentOptions={paginationComponentOptions}
                />
            </div>
        </Fragment>
    );
};
export default Userstable;