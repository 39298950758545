import { H4, H6, LI, P, UL, Image } from '../../../../AbstractElements';
import PagesSort from './Pages';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';


const AllTabs = () => {
    const [tabsData, setTabsData] = useState();
    useEffect(() => {
        /* axios.get(SearchAllTabsApi).then((resp) => {
             setTabsData(resp.data);
         });
         */
    }, []);
    return (
        <Fragment>
            <div className="search-links tab-pane fade show active" id="all-links" role="tabpanel" aria-labelledby="all-link">
                <Row>
                    <Col xl="12">
                        <P attrPara={{ className: 'pb-4' }}>About 6,000 results (0.60 seconds)</P>

                        <div className="info-block" >
                            <H6>{"Avis"}</H6>
                            <a href="#javascript">{"#3216"}</a>
                            <P>{"Modification du champ : prix"}</P>
                            <div className="star-ratings">
                                <UL attrUL={{ className: 'simple-list search-info d-flex flex-row' }}>

                                    <LI>{"Gary Bastien"}</LI>
                                    <LI>{"2023-01-20"}</LI>
                                    <LI>{"13:21"}</LI>
                                </UL>
                            </div>
                        </div>


                        <PagesSort />
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};
export default AllTabs;