import { useState, useEffect } from "react";
import { Icon1, Icon2 } from '../../../Data/SvgIcons';
import IncomeCard from './IncomeCard';
import ProfileGreeting from './ProfileGreetingCard';
import GrowthOverview from './GrownCard';
import LatestActivityClass from './LatestActivityCard';
import TimelineCard from './TimelineCard';
import { MapContainer, TileLayer, useMap, Marker, useMapEvents, Popup, Tooltip } from 'react-leaflet'
import { Container, Row, Col } from 'reactstrap';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";


const Dashboard = () => {



  const mapStyles = {
    width: "100%",
    height: "400px"
  };
  const layer = L.tileLayer(`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`, {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });


  const mapParams = {
    center: [45.508888, -73.561668],
    zoom: 10,
    layers: [layer]
  };

  // This useEffect hook runs when the component is first mounted, 
  // similar to componentDidMount() lifecycle method of class-based
  // components:
  useEffect(() => {

    var container = L.DomUtil.get('map');

    if (container != null) {
      container._leaflet_id = null;
    }


    L.map("map", mapParams);
  }, []);



  return (
    <Container fluid={true} className="dashboard-default-sec">
      <Row>
        <Col xl="4" md="12" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="12" md="6" className="">
              <ProfileGreeting />
              <TimelineCard />
            </Col>

          </Row>
        </Col >

        <Col md="12" xxl="8" className="order-8">

          <Row>
            <div>
              <div id="map" style={mapStyles} />
            </div>
          </Row>

          <Row className="m-t-30">
            <Col md="12" xl="6" className="des-xl-50 box-col-12 activity-sec chart_data_left">
              <Row>
                <Col xl="6" md="3" sm="6" className="box-col-3 des-xl-25 rate-sec">
                  <IncomeCard iconClass={<Icon1 />} amount='12' title="Avis émis" percent="95.54%" />
                </Col>
                <Col xl="6" md="3" sm="6" className="box-col-3 des-xl-25 rate-sec">
                  <IncomeCard iconClass={<Icon2 />} amount='124' title='Contestations' percent="90.54%" />
                </Col>
              </Row>


            </Col>
            <Col md="12" xl="6" className="">
              <LatestActivityClass />
            </Col>

          </Row>
        </Col>

      </Row>
    </Container>
  );
};

export default Dashboard;