import React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { firebase_rdb } from '../../../Config/Config';
import SweetAlert from 'sweetalert2';
import { Row, Card, CardBody, Col } from 'reactstrap';
import FsLightbox from 'fslightbox-react';
import { format,formatDistanceStrict,formatDistance,intlFormatDistance  } from 'date-fns'
import { frCA } from 'date-fns/locale'
import { add } from 'date-fns'
import axios from 'axios';

const payCard = (props) => {
    const navigate = useNavigate();

    const dateLocales = {frCA};

    const [showCard, setShowCard] = useState(false) ;
    const [imgToggle, setImgToggle] = useState(false) ;

    const [carBrand, setCardBrand] = useState('') ;
    const [carModel, setCardModel] = useState('') ;
    const [carPlate, setCarPlate] = useState('') ;


    const [ticketImg, setTicketImg] = useState('') ;
    const [ticketID, setTicketid] = useState('') ;
    const [ticketAddress, setTicketAddress] = useState('') ;
    const [ticketPrice, setTicketPrice] = useState('') ;
    const [ticketPriceToPay, setTicketPriceToPay] = useState('') ;
    const [ticketPrice7Days, setTicketPrice7Days] = useState('') ;
    const [ticketPrice30Days, setTicketPrice30Days] = useState('') ;

    const [ticketDate, setTicketDate] = useState('') ;
    const [ticketDateText, setTicketDateText] = useState('') ;
    const [ticketDateText7Days, setTicketDateText7Days] = useState('') ;
    const [ticketDateText30Days, setTicketDateText30Days] = useState('') ;

    const [ticketDateUp, setTicketDateUp] = useState('') ;
    const [ticketTime, setTicketTime] = useState('') ;

    const [ticketDaysPassed, setTicketDayPassed] = useState(0);

    const [firstRun, setFirstRun] = useState(true);

    useEffect(() => {

        if(props.searchTicketID != ''){
            getTicketByID(props.searchTicketID);
        }

    },[props.searchTicketID]);


    const getTicketByID = pSearchID =>{

        var foundTicket = false;
        props.searching(true);

        firebase_rdb.once("value").then(function (snapshot) {

            const dataClients = snapshot.child("clients").child("maxpark-220731").child("agents").val();
                //Loop Through all the Clients
                for (var agents in dataClients) {
                                  
                    //Loop Through all the Clients's tickets  
                    for (var tc in dataClients[agents].tickets) {

                        //Search for a match with the provided ticketID
                        if (tc == pSearchID ) {

                            let foundTicket = dataClients[agents].tickets[tc];
                            
                            //If ticket status is inactive
                            if(foundTicket.status === "Inactif"){
                                setShowCard(false);
                                SweetAlert.fire({ title: 'Avis annulé', text: 'Aucune action nécessaire', icon: 'success' }).then(function(){
                                    window.location.href="/recherche"; 
                                });
                                return false
                            }

                            if(foundTicket.payment.paid){
                                setShowCard(false);
                                SweetAlert.fire({ title: 'Avis complété', text: 'Aucune action nécessaire', icon: 'success' }).then(function(){
                                    window.location.href="/recherche"; 
                                });
                                return false                         
                            }

                            //Show the Card  && Prevent Card from showing while running the page for the first time
                            props.searching(false);
                            setShowCard(true); 
                            setFirstRun(false);
                            //Ticket Data
                            setTicketid(foundTicket.ticketid);
                            setTicketAddress(foundTicket.address);
                            setCardBrand(foundTicket.carbrand);
                            setCardModel(foundTicket.carmodel);
                            setCarPlate(foundTicket.carplate);
                            setTicketDate(foundTicket.date);
                            setTicketTime(foundTicket.time);
                            setTicketImg(foundTicket.img);
                            setTicketPrice(foundTicket.price);
                            setTicketPrice7Days(foundTicket.price7days);
                            setTicketPrice30Days(foundTicket.price30days);

                            //Calculate Date For Future Price Up
                            let currentDate = new Date();
                            currentDate = currentDate.setHours(0, 0, 0, 0);
                            let ticketCurrentDate = format(new Date(foundTicket.date), 'yyyy/MM/dd');
                            ticketCurrentDate = new Date(ticketCurrentDate);
                            //Add 7 days then format it
                            let ticketDateSevenDays = add(new Date(foundTicket.date), { days: 7 });
                            ticketDateSevenDays = new Date(ticketDateSevenDays);
                             
                            //Add 30 days then format it
                            let ticketDateThirtyDays = add(new Date(foundTicket.date), { days: 30 });
                            ticketDateThirtyDays = new Date(ticketDateThirtyDays);   

                            //Set the French Text for the tickets
                            let frTicketDateText = format(ticketCurrentDate, 'EEEE, d MMMM yyyy',{locale: frCA});
                            let frTicketDateText7Days = format(ticketDateSevenDays, 'EEEE, d MMMM yyyy',{locale: frCA});
                            let frTicketDateText30Days = format(ticketDateThirtyDays, 'EEEE, d MMMM yyyy',{locale: frCA}); 
                            setTicketDateText(frTicketDateText);
                            setTicketDateText7Days(frTicketDateText7Days);
                            setTicketDateText30Days(frTicketDateText30Days);
                            
                            let daysPassed = formatDistanceStrict(currentDate, ticketCurrentDate)
     
                            if (daysPassed.includes('month') || daysPassed.includes('year')){
                                daysPassed = 31 // More than a month                      
                            }else if(daysPassed.includes('hours')){
                                daysPassed = 0;                            
                            }else{
                                daysPassed = daysPassed.replace('days');  
                            }
     
                            daysPassed = parseInt(daysPassed);
                            setTicketDayPassed(daysPassed);
                            
                            //Set the ticket price according to the number of days passed
                            if(daysPassed > 7 && daysPassed<30 ){
                                foundTicket.toPayPrice = foundTicket.price7days;
                                setTicketPriceToPay(foundTicket.price7days);
                            
                            }else if (daysPassed > 30){
                                foundTicket.toPayPrice = foundTicket.price30days;
                                setTicketPriceToPay(foundTicket.price30days);
                            
                            }else{
                                foundTicket.toPayPrice = foundTicket.price;
                                setTicketPriceToPay(foundTicket.price);
                            }

                            //If the page is the paypage, set the CarData
                            if(props.payPage){                          
                                props.sendCardData(foundTicket);
                            }  
                                                                     
                            return foundTicket;
                        }

                     }

                }

            if(!foundTicket){
                //Redirect user if no ticket is found and user access pay page
                if(props.payPage){
                   window.location.href="/recherche"; 
                   //location.assign("/recherche");       
                   //window.location.replace("/recherche");
                   return null;

                }else{
                    props.searching(false);  
                    setShowCard(false);
                    SweetAlert.fire({ title: 'Aucun avis trouvé', text: ' Veuillez vérifier le numéro de référence : ' + pSearchID, icon: 'warning' });                                      
                               
                }
                
            }

            return foundTicket;
        })
    };
  

    let paymodalFooter;
    
    const payerAvis = (e) => {
        e.preventDefault();
       
        axios.post(
            "https://api.maxparksolutions.com/create-checkout-session",
            {
                id: ticketID,
                amount: ticketPriceToPay,
              
              },
              {
              headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.STRIPE_SK}`
              }
            }
          )
            .then((response) => {
                console.log("Response : ")
                console.log(response)
                if(response.data.status === "200"){
                    console.log("response is ok")
                    //showRedirectionState()
                }

              return response.data;
            })
            .then((data) => {
              
                if(data.status === "ok"){
                
                //Set Paying State and Beging Polling for data change for 5 mins.


                //Open paiement option on next page 
                window.location.replace(data.url);
                

                }else{

                    alert("Error getting the session for the payment...")
                    window.location.reload();
                }
           
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
    }


    if (!props.payPage) {
        paymodalFooter = <form><button onClick={payerAvis} to={`${process.env.PUBLIC_URL}/payer/` + ticketID + ticketPriceToPay + ticketID} className="btn btn-primary btn-maxpark btn-lg w-100 p-t-15 p-b-15" style={{ marginTop: 20 }} >Payer cet avis</button>
        </form>
    } else {

        if(ticketDaysPassed > 7){
            paymodalFooter = <p style={{ marginTop: 20 }}>Des frais additionnels ont été ajoutées car la date de paiement est échouée.</p>
        }else{
            paymodalFooter = <p style={{ marginTop: 20 }} >Effectuez un paiement maintenant pour éviter les frais additionnels à partir du : {ticketDateText7Days} </p> 
        }
    }

    return (
        <Fragment>
            <Row>
                <Col md="12" xs="12" lg="12">
                    <Card className={`paymodal-container animated   ${firstRun ? 'hideThis' : ''} ${!showCard ? 'fadeOutRight' : 'fadeInRight'}`} id="paymodalview" >
                        <div class="pb-0 card-header header-paymodal text-center"><h5>{'Avis n° ' + ticketID}</h5></div>
                        <div class="row">
                            <div class="col-4 col-sm-4 col-xs-4">

                                <FsLightbox openOnMount={false}
                                    types={[...new Array(ticketImg)]}
                                    toggler={imgToggle}
                                    sources={[ ticketImg ]}
                                />
                                <img src={ticketImg} alt="Galerie" class="media paymodal-img  img-thumbnail media" onClick={(event) => {
                                    imgToggle ? setImgToggle(false) : setImgToggle(true);
                                }} />
                            </div>
                            <div class="col-8 col-sm-8 col-xs-8" style={{ marginLeft: 0, padding: 20 }}>
                                <h6 class="f-w-600 paymodal-car-title">{carBrand} {carModel}</h6>
                                <p>{carPlate}</p>
                            </div>
                        </div>

                        <CardBody style={{ padding: 10 }}>

                            <div class="cal-desc text-left card-body card-paymodal">
                                <h6 class="f-w-600">Contrôlé : </h6>
                                <p>Le {ticketDateText} à {ticketTime}</p>
                                <h6 class="f-w-600">Lieu : </h6>
                                <p>{ticketAddress}</p>
                                <div class="nav-tabs" style={{ marginTop: 20 }} />

                                <div class="row">
                                    <div class="col-6 col-sm-6"> <h6 class="f-w-600">Montant : </h6></div>
                                    <div class="col-6 col-sm-6"><h6 class="f-w-600 card-paymodal-price">{ticketPriceToPay}$</h6></div>
                                </div>

                                <p class={`datePriceUp ${ticketDaysPassed >7   ? 'datePriceHasPassed' : ''}`}>*{ticketPrice7Days}$ après le {ticketDateText7Days}</p>
                                <p class={`datePriceUp ${ticketDaysPassed >30  ? 'datePriceHasPassed' : ''}`}>*{ticketPrice30Days}$ après le {ticketDateText30Days}</p>
                                               
                                {paymodalFooter}
                            </div>
                            {/* 
                            <div className={`loader-box loading-paymodal ${true == true ? '' : 'modalHide'}`}>
                                <div class='loader-container paymodal-loading-container m-t-40 m-b-40'>
                                    <div class='loader-box paymodal-loader-box'>
                                        <div class='paycard-loader'></div>
                                    </div>
                                </div>                               
                                <h3 class='loading-message f-color-primary f-20 m-t-40'>Veuillez patienter ......</h3>                              
                            </div>
                            */}
                        </CardBody>
                    </Card>
                </Col>
            </Row>     
        </Fragment>
    );
};

export default payCard;

