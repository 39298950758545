import { Home, } from 'react-feather';

export const MENUITEMS = [

    {
        menutitle: 'Menu',
        menucontent: 'Liste de contrats active',
        Items: [
            {
                title: 'Accueil', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/`
            },

            {
                title: 'Payer', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/recherche`
            },

            {
                title: 'Services', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/services`
            },
            {
                title: 'Contact', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/contact`
            },
        ]
    },

]