import React, { Fragment, useState, useEffect } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, P } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { firebase_app } from '../../../Config/Config';
import man from '../../../assets/images/dashboard/1.png';
import Logo from '../../../assets/images/logo/maxpark-logo-white@2x-50.png';

const LoginTab = ({ selected }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);

    const [errorForm, setErrorForm] = useState(false);

    const [errorEmailMsg, setErrorEmailMsg] = useState('');
    const [errorPasswordMsg, setErrorPasswordMsg] = useState('');
    const [errorFormMsg, setErrorFormMsg] = useState('');

    const history = useNavigate();

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );
    const [name, setName] = useState(
        localStorage.getItem('Name')
    );

    useEffect(() => {
        localStorage.setItem('profileURL', value);
        localStorage.setItem('Name', name);
    }, [value, name]);
    
	
	const handleChange = event => {

        const name = event.target.name;

        switch (name) {
            case 'email': setEmail(event.target.value); break;
            case 'password': setName(event.target.value); break;
        }

    };
	
	
	    const validateByTarget = (e) => {

        switch (e.name) {

            case 'email':
                if (!email) {
                    setErrorEmail(true);
                    setErrorEmailMsg('Veuillez entrer une addresse courriel');
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {

                    setErrorEmail(true);
                    setErrorEmailMsg('Courriel invalide ex: Jon@email.com');

                } else {
                    setErrorEmail(false);
                }
            break;

            case 'password':
                if (!password) {
                    setErrorPassword(true);
                    setErrorPasswordMsg('Veuillez entrer un mot de passe');
                } 
            break;
        }

    }
	

    const loginAuth = async (e) => {
        e.preventDefault();
        setLoading(true);
        setValue(man);
        setName('Emay Walter');
   
        try {
            await firebase_app.auth().signInWithEmailAndPassword(email, password).then(function () {
                setValue(man);
                setName('Emay Walter');
                setTimeout(() => {
                    history(`${process.env.PUBLIC_URL}/dashboard/home`);
                }, 200);
            });
        } catch (error) {
            setErrorForm(true);
            setErrorFormMsg('Erreur, le courriel et  ou le mot de passe est invalide.')
            /*
            setTimeout(() => {
                setLoading(false);
                toast.error('Erreur, le courriel et ou le mot de passe est invalide.');
                setErrorForm(false);
            }, 5000);*/
        }
    };



    return (
        <Fragment>
      
            <div className="login-card">
           
                <Form className="theme-form">
                    <img class="m-b-40" src={Logo}/>
                    <span className={`error-form m-t-n-30 p-absolute  ${!errorForm ? 'hidden' : ''}`}>{errorFormMsg}</span>
                    <h4 class="f-color-light-text">Authentification</h4>
                    <p class="f-color-light-text">{'Veuillez entrer vos informations'}</p>
                    
                    <FormGroup>
                        <Label className="col-form-label">Courriel</Label>
                        <Input className="form-control"
                         type="email"
                         name="email"
                          required=""
                           onChange={e => setEmail(e.target.value)} 
                           defaultValue={email}
                           onBlur={(event) => { { validateByTarget(event.target) } }}
                           onKeyPress={(event) => { if(errorEmail) { setErrorEmail(false) }}}
                           />
                        <span className={`error-form  ${!errorEmail ? 'hidden' : ''}`}>{errorEmailMsg}</span>
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label className="col-form-label">Mot de passe</Label>
                        <Input className="form-control"
                         type={togglePassword ? 'text' : 'password'}
                          onChange={e => setPassword(e.target.value)}
                          name="password"
                           defaultValue={password}
                            required=""
                            onBlur={(event) => { { validateByTarget(event.target) } }}
                            onKeyPress={(event) => { if(errorPassword) { setErrorPassword(false) }}}
                             />
                        <span className={`error-form  ${!errorPassword ? 'hidden' : ''}`}>{errorPasswordMsg}</span>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? '' : 'show'}></span></div>
                    </FormGroup>
                    <div className="form-group mb-0">
                        <div className="checkbox ms-3">
                            <Input id="checkbox1" type="checkbox" />
                            <Label className="text-muted" for="checkbox1">Mémoriser</Label>
                        </div><a className="link" href="#javascript">Mot de passe oublié</a>

                    </div>
                    <Btn attrBtn={{ color: 'primary', className: 'btn-block m-t-20 float-right', disabled: loading ? loading : loading, onClick: (e) => loginAuth(e) }} >{loading ? 'ENVOI..' : 'Authentifier'}</Btn>

                </Form>
            </div>
            
          
        </Fragment>
    );
};

export default LoginTab;