import { H4, P, Image } from '../../../../AbstractElements';
import PagesSort from './Pages';
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import axios from 'axios';
import { BigImageApi, ImageLightApi } from '../../../../api';

const PhotosTab = () => {
    const description = 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.';
    const [imagess, setImage] = useState([]);
    const [smallImages, setsmallImages] = useState([]);
    const initilindex = { index: 0, isOpen: false };
    const [photoIndex, setPhotoIndex] = useState(initilindex);

    const onMovePrev = () => {
        const prev = (photoIndex.index + imagess.length - 1) % imagess.length;
        setPhotoIndex({ ...photoIndex, index: prev });
    };

    const onMoveNext = () => {
        const next = (photoIndex.index + 1) % imagess.length;
        setPhotoIndex({ ...photoIndex, index: next });
    };

    useEffect(() => {

        axios.get(ImageLightApi).then((response) => {
            setImage(response.data.src);
        });

        axios.get(BigImageApi).then((response) => {
            setsmallImages(response.data.src);
        });

    }, []);
    var images = '../../../assets/images';
    const dynamicImage = (image) => {
        return images(`./${image}`);
    };
    return (
        <Fragment>
            <Row>

                <Col sm="12">
                    <Card>
                        <CardBody className="my-gallery row gallery-with-description">

                            <figure className="col-xl-3 col-sm-6"><a href="#javascript" data-size="1600x950">
                                <Image attrImage={{
                                    src: ``,
                                    alt: 'Gallery',
                                    className: 'img-thumbnail',
                                    onClick: () =>
                                        setPhotoIndex({ ...photoIndex, index: 1, isOpen: true })
                                }} />
                                <div className="caption">
                                    <H4>{"DEMO"}</H4><P>{description}</P></div></a>
                            </figure>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <PagesSort />
            {photoIndex.isOpen && (
                <Lightbox
                    mainSrc={include(`../../../assets/images/search-not-found.png`)}
                    nextSrc={include(`../../../assets/images/search-not-found.png`)}
                    prevSrc={include(`../../../assets/images/search-not-found.png`)}
                    imageTitle={photoIndex.index + 1 + '/' + imagess.length}
                    onCloseRequest={() => setPhotoIndex({ ...photoIndex, isOpen: false })}
                    onMovePrevRequest={onMovePrev}
                    onMoveNextRequest={onMoveNext}
                />
            )}
        </Fragment>
    );
};

export default PhotosTab;