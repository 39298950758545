import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import { Col, Form, Input } from 'reactstrap';
import { MENUITEMS } from '../../Sidebar/Menu';
import { firebase_rdb } from '../../../../Config/Config';
import { db } from '../../../../Config/Config';
import {  useNavigate  } from 'react-router-dom';
import { LI, UL } from '../../../../AbstractElements';
import EmpltyClass from '../AdminBar/Bookmark/EmptyClass';
import CustomizerContext from '../../../../_helper/Customizer';
import ListOfMenu from '../AdminBar/Bookmark/ListOfMenu';

const Searchbar = (props) => {
    const mainmenu = MENUITEMS;
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [openResp, setOpenResp] = useState(false);
    const [searchIcon, setSearchIcon] = useState(false);
    const [searchResult, setSearchResult] = useState(false);
    const [searchToggle, setSearchToggle] = useState(false);
    const [searchContent, setSearchContent] = useState([]);

    const { setIsClose } = useContext(CustomizerContext);
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            setSearchValue('');
            setSearchResult([]);
            setIsClose(false);
        }
    }, [setIsClose]);

    useEffect(() => {
        //Fetch Tickets Data
       
    /*
       fetchTickets("test","test2").then((data) =>{
            if(data){
                setSearchContent(data);
                console.log(data)
            }
           
        });
    */


        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction, searchValue]);

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            setSearchIcon(true);
        } else {
            setSearchIcon(false);
        }
    };

    const addFix = () => {
        setSearchToggle(true);
    };

    const removeFix = useCallback(() => {
        setSearchValue('');
        setSearchToggle(false);
        setIsClose(false);
    }, [setIsClose]);

    const handleSearchKeyword = (keyword) => {
        keyword ? addFix() : removeFix();
        const items = [];
        setSearchValue(keyword);
    
      
        checkSearchResultEmpty(searchContent);
        setSearchResult(searchContent);
    };

    const submitSearch = () => {
        return navigate(`/dashboard/search/${searchValue}`);
    };

    const handleSubmit = e => {
        e.preventDefault();
        return navigate(`/dashboard/search/${searchValue}`);
      }
      
      const setSearchFocus = e => {
        //Set the UpState Value for the parent object
        props.searchtoggle(true);
      }

      const removeSearchFocus = e => {
        //Set the UpState Value for the parent object
        props.searchtoggle(false);
       }

    return (
        <Fragment>
            <Col className="left-menu-header">
                <UL attrUL={{ className: 'simple-list' }}>
                    <LI>
                        <Form className="form-inline search-full" action={`/dashboard/search/${searchValue}`} onSubmit={handleSubmit} method="get">
                            <div className="form-group w-100">
                                <div className="Typeahead Typeahead--twitterUsers">
                                    <div className="search-bg" >
                                        <i className="fa fa-search" onClick={() => submitSearch()}></i>
                                        <Input className={`form-control-plaintext searchIcon open`} type="text" placeholder="Recherche" defaultValue={searchValue} onChange={(e) => handleSearchKeyword(e.target.value)}
                                        onFocus={(e) => setSearchFocus(e.target.value)}
                                        onBlur={(e) => removeSearchFocus(e.target.value)}  />
                                    </div>
                                    {
                                        /* <ListOfMenu searchValue={searchValue} searchResult={searchResult} removeFix={removeFix} searchBar={true} searchToggle={searchToggle} />
                                    <EmpltyClass searchIcon={searchIcon} search={true} /> */
                                    }
                                   
                                </div>
                            </div>
                        </Form>
                    </LI>
                </UL>
            </Col>
        </Fragment >
    );
};

export default Searchbar;