import { useEffect, useState } from 'react';
import {  Route, Routes , Navigate  } from 'react-router-dom';
import { routes } from './Routes';
import { firebase_app } from '../Config/Config';
import AppLayoutAdmin from '../Pages/Dashboard/LayoutAdmin';
import AppLayoutClient from '../Pages/Website/LayoutClient';

const LayoutRoutes = () => {

  const [currentUser, setCurrentUser] = useState(false);
  //const [currentUserUID, setCurrentUserUID]= useState(false);

  useEffect(() => {
    firebase_app.auth().onAuthStateChanged(setCurrentUser);
    //setCurrentUserUID(currentUser.uid);

}, [currentUser]);


  return (
    <>
      <Routes>
        {routes.map(({ path, Component, type}, i) => (
          <Route element={type ==="client" ? <AppLayoutClient /> : <AppLayoutAdmin /> } key={i}>
            {type ==="dashboard" 
            
            //If Admin Routes, Check if user is logged in and output routes or redirect
            ? currentUser != null 
              ?  <Route path={path} element={Component} /> 
              :  <Route path={path} element={<Navigate to="/auth" /> } />
            
            //If Client Routes output the routes as normal
            : <Route path={path} element={Component} /> }
            
          </Route>
        ))}
      </Routes>
    </>
  );
};

export default LayoutRoutes;