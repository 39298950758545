import React, { Fragment, useState, useEffect } from "react";
import { Btn, H4, H5, Breadcrumbs } from "../../../../AbstractElements";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import { Save, AddUserTxt, firstnameTxt, lastnameTxt, emailTxt, hireDateTxt, statusTxt, StatusMapping, userTypeMapping, salaryTxt, bonusTxt, userTypeTxt, phoneTxt, terminationDateTxt, sendEmailTxt } from '../../../../Constant';
import { collection, getDocs, where, query, setDoc, doc } from "firebase/firestore";
import { db } from '../../../../Config/Config';
import FsLightbox from 'fslightbox-react';
import userimg from '../../../../assets/images/user/user.png';
import DatePicker from 'react-datepicker';
import axios from 'axios';

const AddUser = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [imgToggle, setImgToggle] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [hireDate, setHireDate] = useState(new Date());
    const [salary, setSalary] = useState('');
    const [phone, setPhone] = useState('');
    const [bonus, setBonus] = useState('0');
    const [userStatus, setUserStatus] = useState('Actif');
    const [userType, setUserType] = useState('Agent');
    const [endDate, setEndDate] = useState('');
    const [userPhoto, setUserPhoto] = useState('');
    const [employeeID, setEmployeeID] = useState('');
    const [sendPasswordEmail, setSendPasswordEmail] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingCompleted, setLoadingCompleted] = useState(false);

    const [birthdate, setBirthdate] = useState('');

    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorLastName, setErrorLastName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorHireDate, setErrorHireDate] = useState(false);
    const [errorEndDate, setErrorEndDate] = useState(false);
    const [errorSalary, setErrorSalary] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorUserType, setErrorUserType] = useState(false);
    const [errorForm, setErrorForm] = useState(false);

    const [errorFormMsg, setErrorFormMsg] = useState('');
    const [errorFieldMsg, setErrorFieldMsg] = useState('Veuillez remplir ce champ');
    const [errorEmailMsg, setErrorEmailMsg] = useState('');
    const [errorSalaryMsg, setErrorSalaryMsg] = useState('');

    const mounted = false;

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            getAllEmployeeIDs();
        }

    }, [mounted]);

    const validateForm = (e) => {

        let isValid = true // By Default the form is set to be valid.

        if (!firstName) { setErrorFirstName(true); isValid = false; } else { setErrorFirstName(false); }

        if (!lastName) { setErrorLastName(true); isValid = false; } else { setErrorLastName(false); }

        if (!phone) { setErrorPhone(true); isValid = false; } else { setErrorPhone(false); }

        if (!email) { setErrorEmail(true); setErrorEmailMsg('Veuillez entrer une addresse courriel'); isValid = false; }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) { setErrorEmail(true); setErrorEmailMsg('Veuillez entrer une addresse courriel valide par exemple : John.Smith@email.com'); isValid = false; }
        else { setErrorEmail(false); }

        if (!hireDate) { setErrorHireDate(true); isValid = false; } else { setErrorHireDate(false); }

        if (!salary) {
            setErrorSalary(true);
            setErrorSalaryMsg('Veuillez entrer un salaire');
            isValid = false;



        } else if (!/^[0-9]{0,2}(\.[0-9]{2})?$/i.test(salary)) {
            setErrorSalaryMsg('Veuillez entrer un salaire format : 00.00');
            setErrorSalary(true);
            isValid = false;
        }

        return isValid;
    }

    const onAddSubmit = (data) => {

        if (validateForm()) {

            setLoading(true);

            let newUserRequest = {
                employeeid: employeeID,
                useremail: email,
                phone: phone,
                firstname: firstName,
                lastname: lastName,
                photo: userPhoto,
                hiredate: hireDate,
                usertype: userType,
                userstatus: userStatus,
                salary: salary,
                bonus: bonus,
                sendpassword: sendPasswordEmail
            }

            axios.post('https://api.maxparksolutions.com/create/user', newUserRequest)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    let reqStatus = data.status;
                    let reqMsg = data.msg;

                    if(reqStatus == "success"){
                        setLoadingCompleted(true);      
                    }else{
                        setLoading(false);
                        setLoadingCompleted(false); 
                        setErrorForm(true);
                        setErrorFormMsg(reqMsg);     
                    }

                  

                })
                .catch(error => {
                    if (error) {
                        console.log(error);
                    }
                    setLoading(false);
                    setErrorForm(true);
                })

        }
    }

    const twoDecimalFormat = event => {
        let key = event.key || event.charCode;
        let textVal = event.target.value;

        if (event.key != "Backspace") {
            if (/^[0-9]{2}$/i.test(textVal)) {
                event.target.value = event.target.value + ".";
            }
        }

    };


    function getAllEmployeeIDs() {

        const allEmployeeIDs = [];

        getDocs(collection(db, "clients/maxpark-220731/users")).then(usersDocs => {

            usersDocs.forEach((user) => {
                //console.log(user.get("userData").employeeid);
                allEmployeeIDs.push(user.get("userData").employeeid);
            })


            setNewEmployeeID(allEmployeeIDs);
        });

    };

    const setNewEmployeeID = (dataEmployeeID) => {

        let newEmployeeID = 0;

        if(dataEmployeeID.length >0){

            dataEmployeeID.sort();
            dataEmployeeID.reverse();

            newEmployeeID = parseFloat(dataEmployeeID[0]) + 1;

        }else{
            newEmployeeID = 1000;
        }

        setEmployeeID(newEmployeeID);
    }


    return (
        <Fragment>
            <Breadcrumbs mainTitle={`Utilisateurs`} parent="Utilisateurs" title="Ajouter" />

            {!loading
                ?
                <Form className="card" onSubmit={handleSubmit(onAddSubmit)}>
                    <CardHeader className="pb-0">
                        <H4 attrH4={{ className: "card-title mb-0" }}>{AddUserTxt}</H4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <span className={`error-form ${!errorForm ? 'hidden' : 'fadeIn'}`}>{errorFormMsg}</span>
                                <Row>
                                    <Col md="4">
                                        <div className="profile-title">
                                            <H4 attrH4={{ className: "card-title m-t-20 f-16" }}>Employé : {employeeID}</H4>
                                            <div className="media m-l-0">
                                                <FsLightbox openOnMount={false} types={[...new Array(userimg)]} toggler={imgToggle} sources={[userimg]} />
                                                <img src={userimg} alt="Galerie" class="media paymodal-img m-l-0 img-thumbnail media" onClick={(event) => {
                                                    imgToggle ? setImgToggle(false) : setImgToggle(true);
                                                }} />

                                            </div>
                                        </div>
                                        <div className="form-footer">
                                            <Btn attrBtn={{ className: 'btn-block m-b-20 m-t-20', color: 'primary' }}>Changer la photo</Btn>
                                        </div>
                                    </Col>
                                    <Col md="8">
                                        <Row>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">{firstnameTxt}</Label>
                                                    <Input className="form-control" type="text" placeholder={firstnameTxt} value={firstName} onChange={e => setFirstName(e.target.value)} />
                                                    <span className={`error-form ${!errorFirstName ? 'hidden' : 'fadeIn'}`}>{errorFieldMsg}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">{lastnameTxt}</Label>
                                                    <Input className="form-control" type="text" placeholder={lastnameTxt} value={lastName} onChange={e => setLastName(e.target.value)} />
                                                    <span className={`error-form ${!errorLastName ? 'hidden' : 'fadeIn'}`}>{errorFieldMsg}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">{phoneTxt}</Label>
                                                    <Input className="form-control" type="text" placeholder={phoneTxt} value={phone} onChange={e => setPhone(e.target.value)} />
                                                    <span className={`error-form ${!errorPhone ? 'hidden' : 'fadeIn'}`}>{errorFieldMsg}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">{emailTxt}</Label>
                                                    <Input className="form-control" type="text" placeholder={emailTxt} value={email} onChange={e => setEmail(e.target.value)} />
                                                    <span className={`error-form ${!errorEmail ? 'hidden' : 'fadeIn'}`}>{errorEmailMsg}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">{hireDateTxt}</Label>
                                                    <div className="daterange-card">
                                                        <div className="theme-form">
                                                            <FormGroup >
                                                                <DatePicker
                                                                    className="form-control digits"
                                                                    onChange={(updateDate) => setHireDate(updateDate)}
                                                                    startDate={hireDate}
                                                                    selected={hireDate}
                                                                    calendarStartDay={1}
                                                                    dropdownMode="select"
                                                                    dateFormat="yyyy-MM-dd"
                                                                    todayButton="Aujourd'hui"
                                                                    placeholderText={hireDateTxt}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <span className={`error-form ${!errorHireDate ? 'hidden' : 'fadeIn'}`}>{errorFieldMsg}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">{salaryTxt}</Label>
                                                    <Input className="form-control" type="text" placeholder={salaryTxt} value={salary}
                                                        onChange={e => setSalary(e.target.value)}
                                                        onKeyDown={(event) => twoDecimalFormat(event)}
                                                        onKeyPress={(event) => {

                                                            if (errorSalary) {
                                                                setErrorSalary(false);
                                                            }

                                                            if (!/^[0-9.\x08]+$/.test(event.key)) {
                                                                event.preventDefault();
                                                                setErrorSalaryMsg('Veuillez entrer des numéros seulement')
                                                                setErrorSalary(true);
                                                            }

                                                            if (event.target.value.length > 4) {
                                                                event.target.value = event.target.value.substr(0, 4);
                                                            }
                                                        }}


                                                    />
                                                    <span className={`error-form ${!errorSalary ? 'hidden' : 'fadeIn'}`}>{errorSalaryMsg}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">{bonusTxt}</Label>
                                                    <Input className="form-control" type="text" placeholder={bonusTxt} value={bonus} onChange={e => setBonus(e.target.value)} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup>
                                                    <Label className="form-label">{userTypeTxt}</Label>
                                                    <Input type="select" name="select" className="form-control btn-square" value={userType} onChange={e => setUserType(e.target.value)}>
                                                        {userTypeMapping.map((items, i) =>
                                                            <option key={i} value={items}>{items}</option>
                                                        )};
                                                    </Input>
                                                    <span className={`error-form ${!errorUserType ? 'hidden' : 'fadeIn'}`}>{errorFieldMsg}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup>
                                                    <Label className="form-label">{statusTxt}</Label>
                                                    <Input type="select" name="select" className="form-control btn-square" value={userStatus} onChange={e => setUserStatus(e.target.value)}>
                                                        {StatusMapping.map((items, i) =>
                                                            <option key={i} value={items}>{items}</option>
                                                        )};
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            {userStatus == "Terminé"
                                                ?
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup className="mb-3">
                                                        <Label className="form-label">{terminatioDateTxt}</Label>
                                                        <DatePicker
                                                            className="form-control digits"
                                                            startDate={endDate}
                                                            selected={endDate}
                                                            calendarStartDay={1}
                                                            onChange={(date) => setEndDate(date)}
                                                            dropdownMode="select"
                                                            dateFormat="yyyy-MM-dd"
                                                            todayButton="Aujourd'hui"
                                                            placeholderText={terminationDateTxt}
                                                            minDate={new Date(hireDate)}
                                                        />
                                                        <span className={`error-form ${!errorEndDate ? 'hidden' : 'fadeIn'}`}>{errorFieldMsg}</span>
                                                    </FormGroup>
                                                </Col>
                                                : ""
                                            }

                                        </Row>
                                        <Col xs="12" sm="12" md="12">
                                            <FormGroup className="mb-3">
                                                <div className="checkbox checkbox-solid-primary">
                                                    <Input id="solid3" type="checkbox" checked={sendPasswordEmail} defaultChecked onChange={e => setSendPasswordEmail(!sendPasswordEmail)} />
                                                    <Label for="solid3">{sendEmailTxt}</Label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody >
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: "primary", type: "submit" }} >{Save}</Btn>
                    </CardFooter>
                </Form >
                :
                <Card>
                    <CardBody>
                        <Card className="no-border">
                            <Row>
                                <Col md='12'>
                                    <p class='p-t-30 text-center text-bold'>Veuillez ne pas quitter la page s.v.p. <br /></p>
                                    <p class='p-t-0 text-center'>Création de l'utilisateur : {employeeID} </p>

                                    <div className={`loader-box loading-paymodal`}>
                                        {!loadingCompleted

                                            ?
                                            <div class='loader-container paymodal-loading-container m-t-40 m-b-40'>
                                                <div class='loader-box paymodal-loader-box'>
                                                    <div class='paycard-loader'></div>
                                                </div>
                                            </div>

                                            : <div class='loader-container paymodal-loading-container  m-b-40'>
                                                <i className='fa fa-check-circle f-80 m-t-30 f-color-primary'></i>
                                              </div>

                                        }

                                        {!loadingCompleted

                                            ? <h3 class='loading-message f-color-primary f-20 m-t-40'>Veuillez patienter ......</h3>

                                            : <h3 class='loading-message f-color-primary f-20 m-t-40'>Succès !</h3>

                                        }

                                        {!loadingCompleted

                                        ? <p class='p-t-0 text-center'>Vous serez rediriger vers la liste des utilisateurs automatiquement.</p>

                                        :  <Link to={`${process.env.PUBLIC_URL}/dashboard/users`} className='btn btn-secondary btn-block m-t-40 p-t-15 p-b-15'>Voir la liste des utilisateurs</Link>

                                        }

                                        
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </CardBody>
                </Card>
            }




        </Fragment >
    )
}
export default AddUser