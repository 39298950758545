import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import ContractList from './ContractList';

const Contrats = () => {

    return (
        <Fragment>
            <Col md="12">
                <Row>
                    <ContractList />
                </Row>
            </Col>
        </Fragment>
    );
};
export default Contrats;