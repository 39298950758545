import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import { MoreHorizontal } from 'react-feather';
import MainMenu from './MainMenu';
import AdminBar from '../../Dashboard/Header/AdminBar';
import { Row } from 'reactstrap';
import { useContext } from 'react';
import CustomizerContext from '../../../_helper/Customizer';

const HeaderClient = () => {
    const { toggleSidebarResponsive, toggleSidebar } = useContext(CustomizerContext);
    const [toggle, setToggle] = useState(true);

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
                if (window.innerWidth >= 991) {
                    toggleSidebar(false);
                } 
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    // eslint-disable-next-line
    const [width] = useWindowSize();

    useEffect(() => {

    }, [toggleSidebar]);

    const toggleResp = (value) => {
        setToggle(value);
        toggleSidebarResponsive(toggle);
    };

    return (
        <Fragment>
            
            <div className={`page-main-header header-client`}>
                
                <Row className="main-header-right nav-container-client m-12">
                    
                    {/*<AdminBar />*/}
                    
                    <MainMenu/>
               
                    <div className="d-lg-none mobile-toggle pull-right w-auto" onClick={() => toggleResp(!toggle)}>
                        <MoreHorizontal />
                    </div>
                </Row>
            </div>

            

        </Fragment >
    );
};

export default HeaderClient;