import React, { Fragment, useState, useEffect } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import { Card, CardBody, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { FileText } from 'react-feather';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {H5, H4, H6, P, Image, Btn, H2, Breadcrumbs} from '../../../../AbstractElements';
import {Save, Address, PostalCode, Country, City, State, Telephone, TypeOfService, DateOfSubmission, DateOfContractTermination, FieldCannotBeEmpty, PleaseEnterValidEmail, PleaseEnterValidPhoneNumber, Email, DeleteTxt } from '../../../../Constant';
import {db} from '../../../../Config/Config';
import {collection, getDocs, where, query, updateDoc, doc, deleteDoc} from "firebase/firestore";
import DatePicker from "react-datepicker";

const SingleContractPage = () => {

    const { id } = useParams();

    const [editMode, setEditMode] = useState(false);

    const [shouldRedirect, setShouldRedirect] = useState(false);

    const [currentClient , setCurrentClient] = useState('Maxpark Solutions');

    const [contractData, setContractData] = useState([]);

    const [contractClient, setClient] = useState('');
    const [contractContact, setContact] = useState('');
    const [contractEmail, setEmail] = useState('');
    const [contractTypeOfService ,setTypeOfService] = useState('');
    const [contractDateOfSubmission, setDateOfSubmission] = useState();
    const [contractEndOfContract ,setEndOfContract] = useState();
    const [contractAddress ,setAddress] = useState('');
    const [contractCity ,setCity] = useState('');
    const [contractState ,setState] = useState('');
    const [contractPostalCode ,setPostalCode] = useState('');
    const [contractCountry, setCountry] = useState('');
    const [contractTelephone, setTelephone] = useState('');
    const [contractTelExtension ,setExtension] = useState('');

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const fetchData = async () => {

        let data = {};
        const q = await query(collection(db, "clients/maxpark-220731/contrats"), where("__name__", "==", id.toString()));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            data = doc.data();
        });

        setContractData(data);
        setFields(data);
        reset({
            ...data,
            dateOfSubmission: new Date(data.dateOfSubmission.toDate()),
            endOfContract: new Date(data.endOfContract.toDate())
        });
    }

    const { register, handleSubmit, formState: {errors}, control, reset} = useForm();

    const contractPdf = {
        content: [
            {text: currentClient, style: 'header'},
            "Ce contrat désigne l'offre d'entente entre "+currentClient+ " et " +contractClient+".",
            {text: 'Détails', style: 'subheader'},
            'Exemple',
            {
                style: 'tableExample',
                table: {
                    widths: [100, '*', 200, '*'],
                    body: [
                        ['Contact', 'Email', 'Type of service', 'Date of submission'],
                        [contractClient, {text: contractEmail, italics: true, color: 'gray'}, {text: contractTypeOfService, italics: true, color: 'gray'}, {text: contractDateOfSubmission, italics: true, color: 'gray'}]
                    ]
                }
            },

            {text: 'Autre valeurs du contrat', style: 'subheader'},
            'Affichage superposé:',
            {
                bold: true,
                ul: [
                    'Test 1',
                    'Test 2 ',
                    'Test 3'
                ]
            },
            {
                style: 'tableExample',
                table: {
                    widths: [100, '*', 200, '*'],
                    body: [
                        ['Address', 'City', 'State', 'Country'],
                        [contractAddress, {text: contractCity, italics: true, color: 'gray'}, {text: contractState, italics: true, color: 'gray'}, {text: contractCountry, italics: true, color: 'gray'}]
                    ]
                }
            },

        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            }
        },
        defaultStyle: {
            // alignment: 'justify'
        }

    };

    const setFields = (data) => {
        setClient(data.client);
        setContact(data.contact);
        setEmail(data.email),
        setTypeOfService(data.typeOfService);
        setDateOfSubmission(new Date(data.dateOfSubmission.toDate()));
        setEndOfContract(new Date(data.endOfContract.toDate()));
        setAddress(data.address);
        setCity(data.city);
        setState(data.state);
        setPostalCode(data.postalCode);
        setCountry(data.country);
        setTelephone(data.telephone);
        setExtension(data.telExtension);
    }

    const formSubmit = async (data) => {
        const updatedContractData = {
            client: data.client,
            contact: data.contact,
            email: data.email,
            telephone: data.telephone,
            telExtension: data.telExtension,
            typeOfService: data.typeOfService,
            dateOfSubmission: data.dateOfSubmission,
            endOfContract: data.endOfContract,
            address: data.address,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
            country: data.country,
            id: id.toString()
        }

        await updateDoc(doc(db, "clients/maxpark-220731/contrats", id.toString()), updatedContractData);
    }

    const handleDelete = async () => {
        if (window.confirm("Delete?"))
        {
            console.log(id.toString())
            const q = await query(doc(db, "clients/maxpark-220731/contrats", id.toString()));
            await deleteDoc(q);
            setShouldRedirect(true);
        }
    }

    const navigate = useNavigate();
    useEffect(() => {
        if(shouldRedirect) {
            navigate('/dashboard/contracts');
        }
    }, [shouldRedirect])

    useEffect(() => {
        fetchData();
    }, [reset])

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Contrats' parent="Dashboard" title="Informations Generals" />
            <Card>
                <CardBody>
                    <Form onSubmit={handleSubmit(formSubmit)}>
                        <Row className="">
                            <Col md="8">
                                <H2 attrH6={{ className: 'title' }}>Modifications d'un contrat</H2>
                                <P>Cette liste contient tous les contrats.</P>
                            </Col>
                            <Col md="4">
                                <button type='button' onClick={() => {pdfMake.createPdf(contractPdf).download();}} className='btn btn-secondary btn-block pull-right'><FileText className="h-15"/>Exporter avis</button>

                            </Col>
                        </Row>
                        <Row className="m-t-50">
                            <Col md="9">
                                <Row>
                                    <Col xs="6" sm="6" md="6">
                                        <FormGroup className="mb-6">
                                            <Label className="form-label">Client</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register('client')}></input>
                                            <p>{errors.client? FieldCannotBeEmpty : ''}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="6">
                                        <FormGroup className="mb-6">
                                            <Label className="form-label">Contact</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register('contact')}></input>
                                            <p>{errors.contact? FieldCannotBeEmpty : ''}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="6">
                                        <FormGroup className="mb-6">
                                            <Label className="form-label">{Email}</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register("email", { pattern: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i})}></input>
                                            <p>{errors.email? PleaseEnterValidEmail : ''}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="6">
                                        <FormGroup className="mb-6">
                                            <Label className="form-label">{Telephone}</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register("telephone", { pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im})}></input>
                                            <p>{errors.telephone? PleaseEnterValidPhoneNumber : ''}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="6">
                                        <FormGroup className="mb-6">
                                            <Label className="form-label">Ext</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register('extension')}></input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="6">
                                        <FormGroup className="mb-6">
                                            <Label className="form-label">{TypeOfService}</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register('typeOfService')}></input>
                                            <p>{errors.typeOfService? FieldCannotBeEmpty : ''}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="6">
                                        <FormGroup><Label className="form-label">{DateOfSubmission}</Label>
                                            <Controller
                                                control={control}
                                                name="dateOfSubmission"
                                                // defaultValue={contractDateOfSubmission}
                                                render={({ field: { onChange, onBlur, value}}) => (
                                                    <DatePicker
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        className="form-control"
                                                        disabled={editMode ? false : true}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="6">
                                        <FormGroup><Label className="form-label">{DateOfContractTermination}</Label>
                                            <Controller
                                                control={control}
                                                name="endOfContract"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <DatePicker
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        className="form-control"
                                                        disabled={editMode ? false : true}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup><Label className="form-label">{Address}</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register("address")}></input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="4">
                                        <FormGroup> <Label className="form-label">{City}</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register("city")}></input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="4">
                                        <FormGroup> <Label className="form-label">{State}</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register("state")}></input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6" sm="6" md="4">
                                        <FormGroup><Label className="form-label">{PostalCode}</Label>
                                            <input className="form-control" type="text" disabled={editMode ? false : true} {...register("postalCode")}></input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup><Label className="form-label">{Country}</Label>
                                            <input type="text" className="form-control btn-square" disabled={editMode ? false : true} {...register("country")}></input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="form-footer">
                            <button type='button' className='btn btn-secondary btn-block m-t-30 pull-left'
                                    onClick={e => setEditMode(!editMode)}>{!editMode ? 'Modifier' : 'Annuler'} </button>
                            {!editMode ? '' : <button type='button' className='btn btn-danger btn-block m-t-30 m-l-10 pull-left'
                                                              onClick={handleDelete}>{DeleteTxt}</button>}
                            <button type='submit' className='btn btn-primary btn-maxpark btn-block m-t-30 pull-right'>{Save}</button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    );
};
export default SingleContractPage;