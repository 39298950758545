// Client Pages
import MainPage from '../Pages/Website/MainPage';
import PayPage from '../Pages/Website/Payer';
import SearchPage  from '../Pages/Website/Search';
import ServicesPage from '../Pages/Website/Services';
import ContactPage  from '../Pages/Website/Contact';
import ErrorPage from '../Pages/Website/Error/ErrorPage';
import PaymentSuccessPage from '../Pages/Website/Payer/Success';
import MaintenancePage from '../Pages/Website/Error/Maintenance';
//Authentification

// Admin Pages
import Avis from '../Pages/Dashboard/Avis';
import Default from '../Pages/Dashboard/MainPage';
import SingleAvis from '../Pages/Dashboard/Avis/SingleAvis';
import Users from '../Pages/Dashboard/Users';
import SingleUser from '../Pages/Dashboard/Users/SingleUser';
import AddUser from '../Pages/Dashboard/Users/AddUser';
import Contracts from '../Pages/Dashboard/Contracts';
import SingleContract from '../Pages/Dashboard/Contracts/SingleContract';
import AddContract from '../Pages/Dashboard/Contracts/AddContract';
import AdminSearchPage from '../Pages/Dashboard/SearchPage';

import ReportAvisPage from '../Pages/Dashboard/Reports/Avis';
import ReportUsersPage from '../Pages/Dashboard/Reports/Users';
import ReportsContractPage from '../Pages/Dashboard/Reports/Contrats';
import ReportHeatMapPage from '../Pages/Dashboard/Reports/Heatmap';
import ReportHistoryPage from '../Pages/Dashboard/Reports/History';



import MobileAvisSettings from '../Pages/Dashboard/Mobile';

export const routes = [
        
        //Client Routes
        { path: `${process.env.PUBLIC_URL}*`, Component:<ErrorPage /> , type:"client"  },
        { path: `${process.env.PUBLIC_URL}/`, Component: <MainPage />, type:"client" },
        { path: `${process.env.PUBLIC_URL}/recherche`, Component: <SearchPage />, type:"client" },
        { path: `${process.env.PUBLIC_URL}/payer-un-avis`, Component: <SearchPage />, type:"client" },
        { path: `${process.env.PUBLIC_URL}/payer/:id`, Component: <PayPage /> , type:"client"},
        { path: `${process.env.PUBLIC_URL}/services`, Component: <ServicesPage />, type:"client" },
        { path: `${process.env.PUBLIC_URL}/contact`, Component: <ContactPage />, type:"client" },
        { path: `${process.env.PUBLIC_URL}/payer/success/:token`, Component: <PaymentSuccessPage />, type:"client" },

        //Dashboard Routes
        { path: `${process.env.PUBLIC_URL}*`, Component:<ErrorPage /> , type:"dashboard" , user:"admin" },
        { path: `${process.env.PUBLIC_URL}avis`, Component: <Avis /> , type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}avis/:id`, Component: <SingleAvis /> , type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}home`, Component: <Default /> , type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}contracts`, Component: <Contracts /> , type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}contracts/:id`, Component: <SingleContract /> , type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}contracts/add`, Component: <AddContract /> , type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}users`, Component: <Users /> , type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}users/add`, Component: <AddUser /> , type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}users/:id`, Component: <SingleUser />, type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}reports/avis`, Component: <ReportAvisPage />, type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}reports/users`, Component: <ReportUsersPage />, type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}reports/contrats`, Component: <ReportsContractPage />, type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}reports/heatmap`, Component: <ReportHeatMapPage />, type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}reports/history`, Component: <ReportHistoryPage />, type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}search`, Component: <AdminSearchPage />, type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}search/:terms`, Component: <AdminSearchPage />, type:"dashboard", user:"admin" },
        { path: `${process.env.PUBLIC_URL}mobile`, Component: <MobileAvisSettings />, type:"dashboard", user:"admin" }
]

