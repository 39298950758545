import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { H5, H4, Btn } from '../../../../AbstractElements';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { UsersCountryMenu, UsersStateMenu, Address, PostalCode, Country, State, City, avisStatusList, avisCarColorList, statusPaiement, SaveTxt } from '../../../../Constant';
import FsLightbox from 'fslightbox-react';
import { firebase_rdb } from '../../../../Config/Config';

const EditAvisPage = (props) => {

    const [loadingCompleted, setLoadingCompleted] = useState(false);

    const [carBrand, setCarBrand] = useState('');
    const [carModel, setCarModel] = useState('');
    const [carPlate, setCarPlate] = useState('');
    const [carColor, setCarColor] = useState('');
    const [carSerial, setCarSerial] = useState('');

    const [ticketImg, setTicketImg] = useState('');
    const [ticketID, setTicketid] = useState('');
    const [ticketAgent, setTicketAgent] = useState('');
    const [ticketStatus, setTicketStatus] = useState('');
    const [ticketContract, setTicketContract] = useState('');
    const [ticketClient, setTicketClient] = useState('');
    const [ticketAddress, setTicketAddress] = useState('');
    const [ticketCity, setTicketCity] = useState('');
    const [ticketState, setTicketState] = useState('');
    const [ticketPostalCode, setTicketPostalCode] = useState('');
    const [ticketCountry, setTicketCountry] = useState('');
    const [ticketPrice, setTicketPrice] = useState('');
    const [ticketPrice7Days, setTicketPrice7Days] = useState('');
    const [ticketPrice30Days, setTicketPrice30Days] = useState('');
    const [ticketPayment, setTicketPayment] = useState('');
    const [ticketRule, setTicketRule] = useState('');
    const [currentTicketNode, setCurrentTicketNode] = useState({});

    const [editMode, setEditMode] = useState(false);


    const [ticketDate, setTicketDate] = useState('');
    const [ticketTime, setTicketTime] = useState('');
    const [ticketNote, setTicketNote] = useState('');
    const [ticketNoteAdmin, setTicketNoteAdmin] = useState('');

    const [agentUID, setAgentUID] = useState('');


    const [imgToggle, setImgToggle] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();



    const onEditSubmit = (data) => {
        alert(data)
    }


    useEffect(() => {

        if (props.avis != '') {
            getTicketByID(props.avis);
        }
        
    }, [props.avis]);


    const onSubmit = (e) => {

        e.preventDefault();

        SweetAlert.fire({ title: `Avis n° ${props.avis}`, text: `Voulez vous sauvegarder les changements ?`, icon: 'warning', showCancelButton: true, reverseButtons: true }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                updateTicket();
                setEditMode(false);
                toast.info(`L'avis n° ${props.avis} a été modifié avec succèss`);

            } else if (result.isDenied) {
                
                return false;
            }
        });

    }

    function updateTicket() {

        let updateFields = {
            ticketid: ticketID,
            address: ticketAddress,
            city: ticketCity,
            postalcode: ticketPostalCode,
            country: ticketCountry,
            state: ticketState,
            agent: ticketAgent,
            carbrand: carBrand,
            carcolor: carColor,
            carmodel: carModel,
            carplate: carPlate,
            carserial: carSerial,
            contract: ticketContract,
            contractname: ticketClient,
            date: ticketDate,
            note: ticketNote,
            noteadmin: ticketNoteAdmin ? ticketNoteAdmin : '' ,
            payment: {
                paid: ticketPayment == "false" || ticketPayment == false ? false : true,
            },
            price: ticketPrice,
            price30days: ticketPrice30Days,
            price7days: ticketPrice7Days,
            rule: ticketRule,
            status: ticketStatus,
            time: ticketTime,
        }

        let dbTicketNode = firebase_rdb.child("clients").child('maxpark-220731').child('agents').child(agentUID).child('tickets').child(ticketID);
        dbTicketNode.update(updateFields);

    }




    const getTicketByID = pSearchID => {

        var foundTicket = false;

        firebase_rdb.once("value").then(function (snapshot) {

            const dataClients = snapshot.child("clients").child("maxpark-220731").child("agents").val();
            //Loop Through all the Clients
            for (var agents in dataClients) {

                let AgentID = agents;

                //Loop Through all the Clients's tickets  
                for (var tc in dataClients[agents].tickets) {

                    //Search for a match with the provided ticketID
                    if (tc == pSearchID) {

                        var TicketNode = snapshot.child("clients").child("maxpark-220731").child("agents").child(AgentID).child('tickets').child(tc);
                        let foundTicket = dataClients[agents].tickets[tc];

                        //Agent Data
                        setAgentUID(AgentID);

                        //Ticket Data
                        setTicketImg(foundTicket.img);
                        setTicketid(foundTicket.ticketid);

                        setTicketStatus(foundTicket.status);
                        setTicketAgent(foundTicket.agent);
                        setTicketAddress(foundTicket.address);
                        setTicketCity(foundTicket.city);
                        setTicketPostalCode(foundTicket.postalcode);
                        setTicketState(foundTicket.state);
                        setTicketCountry(foundTicket.country);
                        setTicketClient(foundTicket.contractname)

                        setTicketContract(foundTicket.contract)
                        setCarBrand(foundTicket.carbrand);
                        setCarModel(foundTicket.carmodel);
                        setCarColor(foundTicket.carcolor);
                        setCarPlate(foundTicket.carplate);
                        setCarSerial(foundTicket.carserial);

                        setTicketDate(foundTicket.date);
                        setTicketTime(foundTicket.time);
                        setTicketRule(foundTicket.rule);
                        setTicketPayment(foundTicket.payment ? foundTicket.payment.paid : false);

                        setTicketPrice(foundTicket.price);
                        setTicketPrice7Days(foundTicket.price7days);
                        setTicketPrice30Days(foundTicket.price30days);

                        setTicketNote(foundTicket.note);
                        setTicketNoteAdmin(foundTicket.noteadmin);

                        setCurrentTicketNode(TicketNode);
                        setLoadingCompleted(true);
                        return foundTicket;
                    }

                }

            }

            if (!foundTicket) {

                window.location.href = "/dashboard/avis/";
                //location.assign("/recherche");       
                //window.location.replace("/recherche");
                return null;

            }

            return foundTicket;
        })
    };


    return (
        <Fragment>
            <Card>
                <CardBody>

                    <Col md="12">
                        <Row>

                        </Row>

                    </Col>
                    <Form>
                        <Row>
                            {!loadingCompleted
                                
                                ?
                                <><p class='p-t-30 text-center text-bold'>Veuillez ne pas quitter la page s.v.p. <br /></p><p class='p-t-0 text-center'>Affichage de l'avis : {props.avis} </p><div className={`loader-box loading-paymodal`}>
                                    <div class='loader-container paymodal-loading-container m-t-40 m-b-40'>
                                        <div class='loader-box paymodal-loader-box'>
                                            <div class='paycard-loader'></div>
                                        </div>
                                    </div>
                                </div></>

                                : <><Col md="4">

                                    <Row>
                                        <Card>
                                            <CardBody>
                                                <H4 attrH4={{ className: 'card-title mb-0 m-b-20' }}>Informations sur le véhicule</H4>
                                                <div className="profile-title">
                                                    <div className="media m-l-0">
                                                        <FsLightbox openOnMount={false}
                                                            types={[...new Array(ticketImg)]}
                                                            toggler={imgToggle}
                                                            sources={[ticketImg]} />
                                                        <img src={ticketImg} alt="Galerie" class="media paymodal-img m-l-0 img-thumbnail media" onClick={(event) => {
                                                            imgToggle ? setImgToggle(false) : setImgToggle(true);
                                                        } } />

                                                        <div className="media-body">
                                                            <H5 className="">Photo: <span className={'mb-1 f-20 txt-primary'}>{ticketID}</span></H5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-footer">
                                                    <Btn attrBtn={{ className: 'btn-block m-b-20', color: 'primary' }}>Changer la photo</Btn>
                                                </div>

                                                <Row>
                                                    <Col xs="6" sm="6" md="6">
                                                        <FormGroup><Label className="form-label">Marque</Label>
                                                            <Input className="form-control" type="text" disabled={editMode ? false : true} value={carBrand} onChange={e => setCarBrand(e.target.value)} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6">
                                                        <FormGroup><Label className="form-label">Modèle</Label>
                                                            <Input className="form-control" type="text" disabled={editMode ? false : true} value={carModel} onChange={e => setCarModel(e.target.value)} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6">
                                                        <FormGroup><Label className="form-label">Couleur</Label>
                                                            <Input type="select" name="select" className="form-control btn-square" disabled={editMode ? false : true} onChange={e => setCarColor(e.target.value)}>
                                                                {avisCarColorList.map((items, i) => items == carColor ? <option key={i} selected>{items}</option> : <option key={i}>{items}</option>
                                                                )}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6">
                                                        <FormGroup><Label className="form-label">Plaque</Label>
                                                            <Input className="form-control" type="text" disabled={editMode ? false : true} value={carPlate} onChange={e => setCarPlate(e.target.value)} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12">
                                                        <FormGroup><Label className="form-label">Numéro de Série</Label>
                                                            <Input className="form-control" type="text" disabled={editMode ? false : true} value={carSerial} onChange={e => setCarSerial(e.target.value)} />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="12">
                                                        <div> <Label className="form-label m-t-10">Note Interne</Label>
                                                            <Input type="textarea" className="form-control" disabled={editMode ? false : true} placeholder="Note pour l'équipe Maxpark" rows="3" value={ticketNoteAdmin} onChange={e => setTicketNoteAdmin(e.target.value)} />
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Row>
                                </Col><Col md="8">

                                        <Row className="m-l-20">
                                            <H4 attrH4={{ className: 'card-title mb-0 m-b-20' }}>Informations sur l'avis</H4>
                                            <Row>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup className="mb-6">
                                                        <Label className="form-label">Agent</Label>
                                                        <Input className="form-control" type="text" placeholder="Client" disabled={editMode ? false : true} value={ticketAgent} onChange={e => setTicketAgent(e.target.value)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup className="mb-6">
                                                        <Label className="form-label">Status</Label>
                                                        <Input type="select" name="select" className="form-control btn-square" disabled={editMode ? false : true} defaultValue={ticketStatus} onChange={e => setTicketStatus(e.target.value)}>
                                                            {avisStatusList.map((items, i) => items == ticketStatus ? <option key={i} selected>{items}</option> : <option key={i}>{items}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup className="mb-6">
                                                        <Label className="form-label">Client</Label>
                                                        <Input className="form-control" type="text" placeholder="Client" disabled={editMode ? false : true} value={ticketContract} onChange={e => setTicketContract(e.target.value)} />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup className="mb-6">
                                                        <Label className="form-label">Contrat</Label>
                                                        <Input className="form-control" type="text" placeholder="Client" disabled={editMode ? false : true} value={ticketClient} onChange={e => setTicketClient(e.target.value)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup><Label className="form-label">Date</Label>
                                                        <Input className="form-control" type="text" placeholder="Date" disabled={editMode ? false : true} value={ticketDate} onChange={e => setTicketDate(e.target.value)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup><Label className="form-label">Heure</Label>
                                                        <Input className="form-control" type="text" placeholder="Heure" disabled={editMode ? false : true} value={ticketTime} onChange={e => setTicketTime(e.target.value)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup><Label className="form-label">{Address}</Label>
                                                        <Input className="form-control" type="text" placeholder="Home Address" disabled={editMode ? false : true} value={ticketAddress} onChange={e => setTicketAddress(e.target.value)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="3">
                                                    <FormGroup> <Label className="form-label">{City}</Label>
                                                        <Input className="form-control" type="text" placeholder="Ville" disabled={editMode ? false : true} value={ticketCity} onChange={e => setTicketCity(e.target.value)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="3">
                                                    <FormGroup><Label className="form-label">{PostalCode}</Label>
                                                        <Input className="form-control" type="text" placeholder="Code postal" disabled={editMode ? false : true} value={ticketPostalCode} onChange={e => setTicketPostalCode(e.target.value)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="3">
                                                    <FormGroup><Label className="form-label">{State}</Label>
                                                        <Input type="select" name="select" className="form-control btn-square" disabled={editMode ? false : true} defaultValue={ticketState} value={ticketState} onChange={e => setTicketState(e.target.value)}>
                                                            {UsersStateMenu.map((items, i) => items == ticketState ? <option key={i} selected>{items}</option> : <option key={i}>{items}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="3">
                                                    <FormGroup><Label className="form-label">{Country}</Label>
                                                        <Input type="select" name="select" className="form-control btn-square" disabled={editMode ? false : true} defaultValue={ticketCountry} value={ticketCountry} onChange={e => setTicketCountry(e.target.value)}>
                                                            {UsersCountryMenu.map((items, i) => items == ticketCountry ? <option key={i} selected>{items}</option> : <option key={i}>{items}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="12">
                                                    <FormGroup><Label className="form-label">Règlement</Label>
                                                        <Input className="form-control" type="text" disabled={editMode ? false : true} onChange={e => e.lenghth >= 0 ? setTicketRule(e.target.value) : ""} value={ticketRule} />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs="6" sm="3" md="3">
                                                    <FormGroup><Label className="form-label">Prix régulier</Label>
                                                        <Input className="form-control" type="text" disabled={editMode ? false : true} onChange={e => setTicketPrice(e.target.value)} value={ticketPrice} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="3" md="3">
                                                    <FormGroup><Label className="form-label">Prix 7 jours</Label>
                                                        <Input className="form-control" type="text" disabled={editMode ? false : true} value={ticketPrice7Days} onChange={e => setTicketPrice7Days(e.target.value)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="3" md="3">
                                                    <FormGroup><Label className="form-label">Prix 30 jours</Label>
                                                        <Input className="form-control" type="text" disabled={editMode ? false : true} value={ticketPrice30Days} onChange={e => setTicketPrice30Days(e.target.value)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="3" md="3">
                                                    <FormGroup><Label className="form-label">Payé</Label>

                                                        <Input type="select" name="select" className="form-control btn-square" disabled={editMode ? false : true} defaultValue={ticketPayment.toString()} value={ticketPayment.toString()} onChange={e => setTicketPayment(e.target.value)}>
                                                            <option key={0}>{"false"}</option>
                                                            <option key={1}>{"true"}</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <div> <Label className="form-label">Note</Label>
                                                        <Input type="textarea" className="form-control" disabled={editMode ? false : true} rows="3" value={ticketNote} onChange={e => setTicketNote(e.target.value)} />
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Row>
                                    </Col><div className="form-footer">
                                        <button type='button' class='btn btn-secondary btn-block m-t-30 pull-left' onClick={e => setEditMode(!editMode)}>{!editMode ? 'Modifier' : 'Annuler'} </button>
                                        <button type='submit' disabled={editMode ? false : true} class='btn btn-primary btn-maxpark btn-block m-t-30 pull-right' onClick={onSubmit}>{SaveTxt}</button>
                                    </div></>

                            }
                  

                            
                        </Row>

                    </Form>

                </CardBody>
            </Card>



        </Fragment>
    );
};
export default EditAvisPage;