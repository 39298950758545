import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import LogoWhite from '../../../assets/images/logo/maxpark-logo-white.svg'
import i18n from '../../../i18n';

const Footer = () => {
  return (
    <Fragment>
      <div className="footer-client b-r-0">

        <Container className="footer-container">

          <Row>
            <Col lg="12" xl="12" md="12" xs="12" sm="12">
              <img src={LogoWhite} className="logo-footer h-35" alt="logo" />
            </Col>
          </Row>
          <Row className="m-t-20">
            <Col lg="3" xl="3" md="3" xs="6">
              <Card className="no-border no-background p-0">
                <CardBody className="font-light p-0">
                  <h5 className="font-light">{i18n.t('Footer_Section_1_Title')}</h5>
                  <ul class="list-footer">
                    <li>1555 Bd de l'Avenir</li>
                    <li>Bureau 306</li>
                    <li>Laval, Québec, H7S-2N5</li>
                    <li>Tél : 450-634-1808</li>
                  </ul>                
                </CardBody>
              </Card>

            </Col>
            <Col lg="3" xl="3" md="3" xs="6">
              <Card className="no-border no-background p-0">
                <CardBody className="font-light p-0">
                  <h5 className="font-light">{i18n.t('Footer_Section_2_Title')}</h5>
                  <ul class="list-footer">
                    <li><Link to={`${process.env.PUBLIC_URL}/services`} className="footer-link">{i18n.t('Footer_Section_2_1_Txt')}</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/services`} className="footer-link">{i18n.t('Footer_Section_2_2_Txt')}</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/services`} className="footer-link">{i18n.t('Footer_Section_2_3_Txt')}</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/services`} className="footer-link">{i18n.t('Footer_Section_2_4_Txt')}</Link></li>

                  </ul>
                </CardBody>
              </Card>

            </Col>
            <Col lg="3" xl="3" md="3" xs="6">
              <Card className="no-border no-background p-0">
                <CardBody className="font-light p-0">
                  <h5 className="font-light">{i18n.t('Footer_Section_3_Title')}</h5>
                  <ul class="list-footer">
                    <li><Link to={`${process.env.PUBLIC_URL}/contact`} className="footer-link">{i18n.t('Footer_Section_3_1_Txt')}</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/contact`} className="footer-link">{i18n.t('Footer_Section_3_2_Txt')}</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/contact`} className="footer-link">{i18n.t('Footer_Section_3_3_Txt')}</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/contact`} className="footer-link">{i18n.t('Footer_Section_3_4_Txt')}</Link></li>
                  </ul>
                </CardBody>
              </Card>

            </Col>
            <Col lg="3" xl="3" md="3" xs="6">
              <Card className="no-border no-background p-0">
                <CardBody className="font-light p-0">
                  <h5 className="font-light">{i18n.t('Footer_Section_4_Title')} <span className="f-color-primary f-w-700"> {i18n.t('Footer_Section_4_Title_Accent')}</span></h5>
                  <Link to={`${process.env.PUBLIC_URL}/contact`} className="btn btn-primary btn-maxpark btn-lg m-t-20 p-t-15 p-b-15">{i18n.t('Footer_Section_4_2_Txt')}</Link>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Footer;