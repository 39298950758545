import { Home, Users,Table,Smartphone,PieChart,FilePlus,Monitor,Map,FileText, List, Clock, Layers,  MapPin, Settings } from 'react-feather';

export const MENUITEMS = [
   /* {
        menutitle: 'General',
        menucontent: 'Tickets,Paiements,Co',
        Items: [
            {
                title: 'Dashboard', icon: Home, type: 'sub', active: true, children: [
                    { path: `${process.env.PUBLIC_URL}/dashboard/default`, title: 'Tickets', icon: Home, type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, title: 'Paiements', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, title: 'Contestations', type: 'link' },
                ]
            }
        ]
    },*/
    {
        menutitle: 'Général',
        menucontent: 'Informations',
        Items: [
            {
                title: 'Accueil', icon: Map, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard/home`
            },
            {
                title: "Avis", icon: FileText, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard/avis`
            },
            {
                title: "Contrats", icon: Layers, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard/contracts`
            },

        ]
    },

    {
        menutitle: 'Utilisateurs',
        menucontent: '',
        Items: [
            {
                title: 'Profiles', icon: Users, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard/users`
            },

            {
                
                title: 'Feuille de Temps', icon: Clock, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard/users/timesheets`
            }
        ]
    },

    {
        menutitle: 'Rapports',
        menucontent: 'Tickets,Paiements,Co',
        Items: [

            {
                title: 'Données', icon: PieChart, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/dashboard/reports/avis`, title: 'Avis', icon: Home, type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/dashboard/reports/users`, title: 'Agents', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/dashboard/reports/contrats`, title: 'Contrats', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/dashboard/reports/heatmap`, title: 'Heat map', type: 'link' },   
                    { path: `${process.env.PUBLIC_URL}/dashboard/reports/history`, title: 'Historique', type: 'link' },   
                ]
            }
    
        ]
    },

    {
        menutitle: 'Application Mobile',
            menucontent: '',
            Items: [

                {
                    title: 'Configuration', icon: Smartphone, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard/mobile`
                }
            ]
        }
]