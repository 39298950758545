import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Footer, P } from '../../../AbstractElements';
import { useLocation } from 'react-router-dom';
import { BackgroundColor } from '../../../Constant';

const FooterClass = () => {
  const location = useLocation();
  
  return (
    <Fragment >
      <div className='footer-bg-color'> 
      <Footer attrFooter={{ className: `footer ${location.pathname === '/viho/page-layout/footer-dark' ? 'footer-dark' : location.pathname === '/viho/page-layout/footer-fixed' ? 'footer-fix' : ''}` }} >
        <Container fluid={true}>
          <Row>
            <Col md="6" className="footer-copyright">
              <P attrPara={{ className: 'mb-0' }} >{'Copyright 2024 ©MaxPark Solutions Tous droits réservés.'}</P>
            </Col>
            <Col md="6">
              <P attrPara={{ className: 'pull-right mb-0' }} >Fait par <a href="https://www.sconcept.ca" target="_blank">Sconcept.Inc</a></P>
            </Col>
          </Row>
        </Container>
      </Footer>
      </div>
    </Fragment>
  );
};

export default FooterClass;