import React, { Fragment } from 'react';
import { Image, LI, P, UL } from '../../../../AbstractElements';
import { firebase_app } from '../../../../Config/Config';
import { LogOut, User, HelpCircle } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { UserProfile } from '../../../../Constant';

const LogoutClass = () => {
    const history = useNavigate();

    const goToProfile = () => {
        //history(`${process.env.PUBLIC_URL}/dashboard/users/`);
    };

    const Logout = () => {
        localStorage.removeItem('profileURL');
        localStorage.removeItem('token');
        firebase_app.auth().signOut();
        localStorage.removeItem('Name');
        localStorage.setItem('authenticated', false);
        history(`${process.env.PUBLIC_URL}/auth`);
    };

    return (
        <Fragment>
            <LI attrLI={{ className: 'onhover-dropdown p-0 noti-primary', onClick: goToProfile }} >
                <User />

                <UL attrUL={{ className: 'notification-dropdown onhover-show-div' }} >
                    <LI>
                        <div className="media">
                            <Image attrImage={{ className: 'img-fluid rounded-circle me-2 img-50', src: `${require('../../../../assets/images/user/2.jpg')}`, alt: '' }} />
                            <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}>
                                <div className="media-body">
                                    <P>John Doe</P><span>Profil d'utilisateur</span>
                                </div>
                            </Link>

                        </div>
                    </LI>
                    <LI attrLI={{ className: 'text-center', onClick: Logout }} >
                        <Link to={`${process.env.PUBLIC_URL}/auth`}>
                            <span className="logout-icon">
                                <LogOut />
                            </span>
                            <div className="media-body">
                                <P>Déconnexion</P>
                            </div>
                        </Link>
                    </LI>
                </UL>
            </LI>
        </Fragment>
    );
};

export default LogoutClass;