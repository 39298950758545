import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../../AbstractElements';
import EditAvis from './EditAvis';

const SingleAvis = () => {

    const { id } = useParams();

    return (
        <Fragment>
            <Breadcrumbs mainTitle={ `Avis n°${id}` } parent="Liste des avis" title="Avis" />
            <Container fluid={true}>
                <div className="edit-profile">
                    <Row>
                        <Col xl="10">
                            <EditAvis avis={id} />
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default SingleAvis;