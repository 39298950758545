import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import CheckContext from '../../_helper/Customizer';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const {toggleSidebar } = useContext(CheckContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    toggleSidebar(false);
  }, [pathname]);

  return null;
}