import Loader from "../../CommonElements/Loader";
import ScrollToTop from "../../CommonElements/ScrollTop";
import HeaderClient from "./HeaderClient";
import SidebarClient from "./HeaderClient/Sidebar";
import Footer from "./Footer";
import Copyright from "./Footer/Copyright";
import React, { Fragment, useContext, useState, useEffect, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AnimationThemeContext from "../../_helper/AnimationTheme";
import ConfigDB from "../../Config/ThemeConfig";

const AppLayoutClient = ({ children, classNames, ...rest }) => {
  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme =
    localStorage.getItem("animation") ||
    animation ||
    ConfigDB.data.router_animation;

  return (
    <Fragment>
          <Loader />
          <SidebarClient />
          <ScrollToTop />
          <div className="page-body-wrapper horizontal-menu">
            <HeaderClient />
            <div className="page-body background-maxpark">
              <TransitionGroup {...rest}>
                <CSSTransition
                  key={location.key}
                  timeout={100}
                  classNames={animationTheme}
                  unmountOnExit
                >
                  <Outlet />
                </CSSTransition>
              </TransitionGroup>
            </div>
            <Footer />
            <Copyright />
          </div>
          <ToastContainer />
    </Fragment>
  );
};
export default AppLayoutClient;