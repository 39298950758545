import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import AvisList from './AvisList';

const Avis = () => {

    return (
        <Fragment>

            <Col md="12">
                <Row>              
                    <AvisList/>
                </Row>
            </Col>
        </Fragment>
    );
};
export default Avis;